import { Box, Paper, Typography } from "@mui/material";
import { FC } from "react";
import { useSelector } from "react-redux";
import useDeliveryPartnerOrders from "../../hooks/useDeliveryPartnerOrders";
import ReduxStateModel from "../../models/ReduxStateModel";
import LoadingSpinner from "../LoadingSpinner";
import RevenueTableListItem from "../../pages/DeliveryPartnerRevenue/RevenueTableListItem";

const DeliveryPartnerOrdersList: FC<DeliveryPartnerOrdersListProps> = () => {
  const authReducer = useSelector((state: ReduxStateModel) => state.authReducer?.setting);
  const { query } = useDeliveryPartnerOrders({ partnerUUID: authReducer?.partnerUUID });

  return (
    <Box padding={2}>
      <Typography fontSize={"large"} marginBottom={2} fontWeight={500} color={"black"}>
        Orders List
      </Typography>
      <Paper sx={{ paddingX: 2, paddingY: 2 }}>
        {query.isLoading ? (
          <LoadingSpinner spinning={true} showBackdrop={false} styles={{ minHeight: "100px" }} children={undefined} />
        ) : (
          query.data?.requests?.map((req, i) => {
            return (
              <RevenueTableListItem key={req.orderUUID} order={req} isLast={i === (query.data?.requests?.length ?? 0) - 1} />
            );
          })
        )}
        {(query.data?.requests?.length ?? 0) === 0 && (
          <Typography width={"100%"} textAlign={"center"}>
            No orders found
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

interface DeliveryPartnerOrdersListProps {}

export default DeliveryPartnerOrdersList;
