import { Box, Button, InputAdornment, List, TextField, Typography } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ConsultationLabTestModel } from "../../models/GetProductsForConsultationResponseModel";
import { ConsultationRecommendationsInitialState } from "../../reducer/consultation.recommendations.reducer";
import { buildYourLabTestProductUUID } from "../../constants";
import { ConsultationRecommendationsType } from "../../types";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import LoadingSpinner from "../../components/LoadingSpinner";
import useProductsForConsultationQuery from "../../hooks/useProductsForConsultationQuery";
import SuggestLabTestsListItem from "./SuggestLabTestsListItem";
import Mixpanel from "../../utils/Mixpanel";
import ReduxStateModel from "../../models/ReduxStateModel";
import SuggestionsModalDialog from "./SuggestionsModalDialog";
import useSearchProducts from "../../hooks/useSearchProducts";
import useOrderDetails from "../../hooks/useOrderDetails";
import useBuildYourLabTestBiomarkers from "../../hooks/useBuildYourLabTestBiomarkers";
import useBiomarkersListQuery from "../../hooks/useBiomarkersListQuery";

const SuggestLabTestsModal: FC<SuggestLabTestsModalProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const [searchText, setSearchText] = useState("");

  const [selectedAddons, setSelectedAddons] = useState<Map<string, boolean>>(() => {
    const value = new Map<string, boolean>();
    if (consultationRecommendationsReducer.selectedBuildYourLabTestItems === "SKIP") return value;
    for (const test of consultationRecommendationsReducer.selectedBuildYourLabTestItems ?? []) {
      value.set(test.biomarkerName ?? "", true);
    }
    return value;
  });

  const isSearching = useMemo(() => searchText.trim().length > 0, [searchText]);

  const { query: orderDetailsQuery } = useOrderDetails({ orderUUID: orderUUID });

  const { query: biomarkersQuery } = useBuildYourLabTestBiomarkers({ searchString: searchText.trim() });

  const { query: biomarkersListQuery } = useBiomarkersListQuery();

  const { query: searchQuery } = useSearchProducts({
    filter: "LAB",
    location: orderDetailsQuery.data?.order?.emirate,
    searchString: searchText.trim(),
  });

  const [selectedTests, setSelectedTests] = useState<Map<string, boolean>>(() => {
    const value = new Map<string, boolean>();
    if (consultationRecommendationsReducer.selectedLabTests === "SKIP") return value;
    for (const test of consultationRecommendationsReducer.selectedLabTests ?? []) {
      value.set(test.productUUID ?? "", true);
    }
    return value;
  });

  const { query } = useProductsForConsultationQuery();

  const fullLabTestsList = useMemo(() => {
    return (query.data?.labTests ?? []).filter((prod) => prod.productUUID !== buildYourLabTestProductUUID);
  }, [query.data?.labTests]);

  const labTestsList = useMemo(() => {
    if (!searchText.trim().length) return fullLabTestsList;
    return (
      searchQuery.data?.products?.map<ConsultationLabTestModel>((prod) => {
        return {
          engDescription: prod.engDescription,
          engName: prod.engProductName,
          productUUID: prod.productUUID,
          mainImageUrl: prod.mainImageUrl,
          name: prod.engProductName,
          testsIncluded: prod.testsIncluded,
        };
      }) ?? []
    );
  }, [fullLabTestsList, searchQuery.data?.products, searchText]);

  const handleClose = useCallback(() => {
    history.push(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  const handleContinueClicked = useCallback(() => {
    const selectedTestsList = fullLabTestsList.filter((test) => {
      return selectedTests.get(test.productUUID ?? "");
    });
    const selectedBuildYourLabTestItems = biomarkersListQuery.data?.addOns?.filter((addon) => {
      return selectedAddons.get(addon.biomarkerName ?? "");
    });
    const payload: Partial<ConsultationRecommendationsInitialState> = {
      selectedLabTests: selectedTestsList,
      selectedBuildYourLabTestItems: selectedBuildYourLabTestItems,
    };
    dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    Mixpanel.track("SuggestLabTestsModal continue clicked", {
      ...payload,
      partnerUser: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    history.push(`/order/${orderUUID}/suggestions`);
  }, [biomarkersListQuery.data?.addOns, dispatch, fullLabTestsList, history, orderUUID, selectedAddons, selectedTests]);

  const handleSkipClicked = useCallback(() => {
    const payload: Partial<ConsultationRecommendationsInitialState> = {
      selectedLabTests: "SKIP",
      selectedBuildYourLabTestItems: "SKIP",
    };
    Mixpanel.track("SuggestLabTestsModal skip clicked", {
      ...payload,
      partnerUser: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    history.push(`/order/${orderUUID}/suggestions`);
  }, [dispatch, history, orderUUID]);

  const didSelectAtLeastOneTest = useMemo(() => {
    return (
      Array.from(selectedTests.values()).some((value) => value) || Array.from(selectedAddons.values()).some((value) => value)
    );
  }, [selectedAddons, selectedTests]);

  return (
    <SuggestionsModalDialog open={true} onClose={handleClose}>
      <Typography display={"flex"} alignItems={"flex-end"} gap={1} marginBottom={2}>
        <Box
          fontWeight={600}
          letterSpacing={"-0.28px"}
          fontSize={"56px"}
          lineHeight={"60px"}
          color={"#1D1D1F"}
          sx={{
            "@media (max-width: 700px)": {
              fontSize: "30px",
            },
          }}
        >
          Lab Tests
        </Box>
      </Typography>
      <Box
        marginBottom={2}
        sx={{
          "& .MuiInputBase-root": {
            border: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <TextField
          placeholder="Search"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.36)" }} />
              </InputAdornment>
            ),
          }}
          fullWidth={true}
          sx={{ backgroundColor: "#F7F7F7", border: "none", borderRadius: "8px" }}
          autoFocus={true}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </Box>
      <LoadingSpinner
        showBackdrop={false}
        spinning={query.isLoading || (isSearching && searchQuery.isLoading) || (isSearching && biomarkersQuery.isLoading)}
        styles={{ minHeight: "100px" }}
      >
        <List
          dense
          sx={{
            width: "100%",
            bgcolor: "#F7F7F7",
            borderRadius: "14px",
            minHeight: "100px",
            maxHeight: "48vh",
            overflow: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              backgroundColor: "unset",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#1D1D1F",
              borderRadius: "10px",
            },
          }}
        >
          {isSearching &&
            biomarkersQuery.data?.result?.map((res) => {
              return (
                <SuggestLabTestsListItem
                  key={res.biomarkerName}
                  type="BIOMARKER"
                  searchText={searchText}
                  selected={selectedAddons.get(res.biomarkerName ?? "") ?? false}
                  test={{
                    name: res.biomarkerName,
                    productUUID: res.biomarkerName,
                    mainImageUrl:
                      "https://dardocstorageaccount.blob.core.windows.net/dardocpictures/imageelwkdjfkasdhfkjasb152.png",
                  }}
                  onSelectClicked={() => {
                    setSelectedAddons((value) => {
                      const newValue = new Map(value);
                      if (!newValue.get(res.biomarkerName ?? "")) {
                        newValue.set(res.biomarkerName ?? "", true);
                      } else {
                        newValue.set(res.biomarkerName ?? "", false);
                      }
                      return newValue;
                    });
                  }}
                />
              );
            })}
          {labTestsList?.map((test) => {
            return (
              <SuggestLabTestsListItem
                key={test.productUUID}
                test={test}
                type="PACKAGE"
                searchText={searchText}
                selected={selectedTests.get(test.productUUID ?? "") ?? false}
                onSelectClicked={function (test: ConsultationLabTestModel): void {
                  setSelectedTests((value) => {
                    const newValue = new Map(value);
                    if (newValue.get(test.productUUID ?? "")) {
                      newValue.set(test.productUUID ?? "", false);
                    } else {
                      newValue.set(test.productUUID ?? "", true);
                    }
                    return newValue;
                  });
                }}
              />
            );
          })}
        </List>
      </LoadingSpinner>
      <Button
        size="large"
        variant={"contained"}
        fullWidth={true}
        disableElevation={true}
        onClick={didSelectAtLeastOneTest ? handleContinueClicked : handleSkipClicked}
        sx={{
          "&.MuiButtonBase-root": {
            backgroundColor: "black",
            color: "white",
          },
          marginTop: 4,
          borderRadius: "30px",
        }}
      >
        {didSelectAtLeastOneTest ? "Continue" : "Go back"}
      </Button>
    </SuggestionsModalDialog>
  );
};

interface SuggestLabTestsModalProps {}

export default SuggestLabTestsModal;
