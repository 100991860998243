import axios from "axios";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import BaseResponseModel from "../models/BaseResponseModel";
import PharmacyBookingModel, { PharmacyBookingStatus, PharmacyOrderItem } from "../models/PharmacyBookingModel";

export interface GetPharmacyOrderResponseModel extends BaseResponseModel {
  pharmacyOrder?: PharmacyBookingModel;
}

export async function updatePharmacyOrderAvaialbleItems(params: { orderUUID: string; items: PharmacyOrderItem[] }) {
  try {
    const res = await axios.post<string>("/api/v1/pharmacy-orders/update-available-items", { data: encrypt(params) });
    const data: BaseResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- updatePharmacyOrderAvaialbleItems error", e);
    return null;
  }
}

export async function getPharmacyOrder(params: { orderUUID: string }): Promise<GetPharmacyOrderResponseModel | null> {
  try {
    const res = await axios.get(`/api/v1/pharmacy-orders/fetch-particular-order/${params.orderUUID}`);
    const data: GetPharmacyOrderResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- fetch-particular-order error", e);
    return null;
  }
}

export async function markOrderAsDelivered({ orderUUID }: { orderUUID: string }): Promise<BaseResponseModel | null> {
  try {
    const res = await axios.post("/api/v1/pharmacy-orders/mark-as-delivered", { data: encrypt({ orderUUID }) });
    const data: BaseResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- markOrderAsDelivered error", error);
    return null;
  }
}

export async function markOrderAsDispatched({ orderUUID }: { orderUUID: string }): Promise<BaseResponseModel | null> {
  try {
    const res = await axios.post("/api/v1/pharmacy-orders/complete-pharmacy-order", { data: encrypt({ orderUUID }) });
    const data: BaseResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- markOrderAsDispatched error", error);
    return null;
  }
}

export interface GetPharmacyOrdersResponseModel extends BaseResponseModel {
  pharmacyOrders?: PharmacyBookingModel[];
}

export async function getPharmacyOrders(params: {
  status: PharmacyBookingStatus;
  partnerUUID: string;
}): Promise<GetPharmacyOrdersResponseModel | null> {
  try {
    const res = await axios.get(`/api/v1/pharmacy-orders/fetch-pharmacy-orders/${params.status}/${params.partnerUUID}`);
    const data: GetPharmacyOrdersResponseModel = decrypt(res.data);
    // console.log("---- pharmacy-orders", params.status, data);
    // console.log(params);
    return data;
  } catch (error) {
    console.log("--- getPharmacyOrders", error);
    return null;
  }
}
