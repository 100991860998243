import { Box, Button, Divider, InputAdornment, ListItemText, TextField } from "@mui/material";
import { FC } from "react";
import { PharmacyOrderItem } from "../../models/PharmacyBookingModel";
import TransitionGroupList from "../TransitionGroupList";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";

const AvailableItemsList: FC<AvailableItemsListProps> = ({ selectedItems, onPriceChange, handleRemoveAvailableItem }) => {
  return (
    <TransitionGroupList
      children={selectedItems.map((item, i) => {
        const element = (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <ListItemText primary={item.productName} />
              <div style={{ display: "flex", gap: "8px" }}>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Item price"
                  value={item.price ?? ""}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (isNaN(value)) return;
                    onPriceChange(item, value);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">AED</InputAdornment>,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "6px",
                    },
                  }}
                />
                <Button
                  variant="outlined"
                  onClick={() => handleRemoveAvailableItem(item)}
                  startIcon={<RemoveCircleOutlineRoundedIcon />}
                >
                  Not Available
                </Button>
              </div>
            </div>
            {i !== selectedItems.length - 1 && <Divider sx={{ width: "100%", marginTop: "1rem" }} />}
          </Box>
        );
        return { element: element, key: `${item.productName} - ${item.dosage}` };
      })}
    />
  );
};

interface AvailableItemsListProps {
  selectedItems: PharmacyOrderItem[];
  onPriceChange: (item: PharmacyOrderItem, price: number) => void;
  handleRemoveAvailableItem: (item: PharmacyOrderItem) => void;
}

export default AvailableItemsList;
