import { Box, Button, Tooltip } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { allowRecommendationsProductUUIDs } from "../../constants";
import { ConsultationRecommendationsType } from "../../types";
import { useDispatch } from "react-redux";
import moment from "moment";
import AssistantRoundedIcon from "@mui/icons-material/AssistantRounded";
import BookingModel from "../../models/BookingModel";
import getStartMomentFromDateObj from "../../utils/getStartMomentFromDateObj";

const isProdEnv = window.location.host === "partner.dardoc.com";

const SuggestIVDripOrLabTestButton: FC<SuggestIVDripOrLabTestButtonProps> = ({ order }) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const startTime = useMemo(() => {
    return getStartMomentFromDateObj({ date: order.date?.[0].date ?? "", time: order.date?.[0].time ?? "" }).subtract(
      15,
      "minute"
    );
  }, [order.date]);

  const [now, setNow] = useState(moment());

  const showSuggestionsButton = useMemo(() => {
    if (!isProdEnv) return true;
    return now.isAfter(startTime);
  }, [now, startTime]);

  useEffect(() => {
    if (showSuggestionsButton) return;
    const intervalID = setInterval(() => {
      setNow(moment());
    }, 2000);
    return () => {
      clearInterval(intervalID);
    };
  }, [showSuggestionsButton]);

  if (order.status !== "PROCESSING") return null;
  if (!order?.isNormalVideoCall) return null;
  if (!allowRecommendationsProductUUIDs.includes(order.patientId?.[0]?.productUUID ?? "")) return null;

  return (
    <Box
      sx={{
        "@media (max-width: 700px)": {
          width: "100%",
          "& a": {
            width: "100%",
            display: "block",
          },
          "& .MuiButtonBase-root": {
            width: "100%",
          },
        },
      }}
    >
      <Tooltip title={showSuggestionsButton ? "" : "Can only add suggestions after call"}>
        <Link to={showSuggestionsButton ? `${url}/suggestions` : url}>
          <Button
            disabled={!showSuggestionsButton}
            startIcon={<AssistantRoundedIcon />}
            variant="contained"
            color="success"
            disableElevation={true}
            sx={{ height: "100%" }}
            onClick={() => {
              dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: {} });
            }}
          >
            Suggest IV Drip or Lab Tests
          </Button>
        </Link>
      </Tooltip>
    </Box>
  );
};

interface SuggestIVDripOrLabTestButtonProps {
  order: BookingModel;
}

export default SuggestIVDripOrLabTestButton;
