import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { FC } from "react";

const SuggestionsModalDialog: FC<SuggestionsModalDialogProps> = ({ onClose, children, open }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
        },
        "@media (max-width: 700px)": {
          "& .MuiPaper-root": {
            margin: "16px",
            width: "calc(100% - 16px)",
          },
        },
      }}
    >
      <DialogTitle sx={{ marginBottom: "0rem", display: "flex", justifyContent: "flex-end", paddingBottom: "0px" }}>
        <IconButton sx={{ color: "hsl(0deg 0% 0% / 80%)" }} onClick={onClose}>
          <img
            src={"https://dardocstorageaccount.blob.core.windows.net/dardocpictures/close-icon.png"}
            alt="Close"
            width={72}
            height={72}
            style={{ width: "30px", height: "30px" }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: 8,
          paddingBottom: "3rem",
          "& .MuiButtonBase-root": {
            minWidth: "300px",
          },
          "@media (max-width: 700px)": {
            paddingLeft: "1rem",
            paddingRight: "1rem",
          },
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

interface SuggestionsModalDialogProps {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

export default SuggestionsModalDialog;
