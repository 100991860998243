import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { LoadingButton } from "@mui/lab";
import BookingModel from "../../models/BookingModel";
import useConfirmRecommendations from "../../hooks/useConfirmRecommendations";

const ConfirmRecommendationsModal: FC<ConfirmRecommendationsModalProps> = ({ setBooking }) => {
  const {
    showLabTestAddon,
    ivDrip,
    ivDripAddons,
    labTests,
    buildYourLabTestAddons,
    showLabTests,
    saveMutation,
    handleClose,
    handleConfirmClicked,
  } = useConfirmRecommendations({ setBooking });

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle sx={{ marginBottom: "2rem", textAlign: "center" }}>Confirm Recommendations</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          paddingBottom: "2rem",
          "& .MuiButtonBase-root": {
            minWidth: "300px",
          },
        }}
      >
        <List
          dense
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            height: "100%",
            minHeight: "60vh",
            maxHeight: "60vh",
            overflow: "scroll",
            "& .iv-drip-list-item": {
              alignItems: "flex-start",
            },
          }}
        >
          {ivDrip && ivDrip !== "SKIP" && (
            <ListItem
              sx={{
                borderBottom: "1px solid #cccccc",
                marginBottom: "1rem",
                "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                  minWidth: "50px",
                },
                "& .MuiListItemButton-root": {
                  alignItems: "flex-start",
                },
              }}
              disablePadding
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle1" fontWeight={600}>
                    IV Drip
                  </Typography>
                }
              />
            </ListItem>
          )}
          {ivDrip && ivDrip !== "SKIP" && (
            <ListItem
              className="iv-drip-list-item"
              sx={{
                borderBottom: "1px solid #cccccc",
                paddingBottom: (ivDripAddons?.length ?? 0) > 0 ? "0px" : "1rem",
                marginBottom: "0.2rem",
                "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                  minWidth: "50px",
                },
                "& .MuiListItemButton-root": {
                  alignItems: "flex-start",
                },
              }}
              disablePadding
            >
              <ListItemAvatar>
                <Avatar sx={{ borderRadius: "4px" }} alt={ivDrip.name} src={ivDrip.mainImageUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" sx={{}}>
                    {ivDrip.name}
                  </Typography>
                }
                secondary={
                  (ivDripAddons?.length ?? 0) > 0 ? (
                    <div>
                      <div style={{ fontWeight: 600 }}>Add-ons:</div>{" "}
                      <ul style={{ padding: "4px 14px" }}>
                        {ivDripAddons?.map((addon) => {
                          return (
                            <li key={addon.customizationUUID} style={{ marginBottom: "0.5rem" }}>
                              {addon.engName}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null
                }
              />
            </ListItem>
          )}
          {(showLabTests || showLabTestAddon) && (
            <ListItem
              sx={{
                borderBottom: "1px solid #cccccc",
                marginBottom: "1rem",
                "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                  minWidth: "50px",
                },
                "& .MuiListItemButton-root": {
                  alignItems: "flex-start",
                },
              }}
              disablePadding
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle1" fontWeight={600}>
                    Lab Tests
                  </Typography>
                }
              />
            </ListItem>
          )}
          {showLabTests &&
            labTests !== "SKIP" &&
            labTests?.map((test) => {
              return (
                <ListItem
                  key={test.productUUID}
                  sx={{
                    borderBottom: "1px solid #cccccc",
                    paddingBottom: "1rem",
                    marginBottom: "1rem",
                    "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                      minWidth: "50px",
                    },
                    "& .MuiListItemButton-root": {
                      alignItems: "flex-start",
                    },
                  }}
                  disablePadding
                >
                  <ListItemAvatar>
                    <Avatar sx={{ borderRadius: "4px" }} alt={test.name} src={test.mainImageUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" sx={{}}>
                        {test.name}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          {showLabTestAddon &&
            buildYourLabTestAddons !== "SKIP" &&
            buildYourLabTestAddons?.map((test) => {
              return (
                <ListItem
                  key={test.customizationUUID}
                  sx={{
                    borderBottom: "1px solid #cccccc",
                    marginBottom: "1rem",
                    "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                      minWidth: "50px",
                    },
                    "& .MuiListItemButton-root": {
                      alignItems: "flex-start",
                    },
                  }}
                  disablePadding
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" sx={{}}>
                        {test.engName}
                      </Typography>
                    }
                    secondary={<Typography variant="subtitle2">{test.engDescription}</Typography>}
                  />
                </ListItem>
              );
            })}
        </List>
        <LoadingButton
          loading={saveMutation.isLoading}
          variant="contained"
          disableElevation={true}
          fullWidth={true}
          onClick={handleConfirmClicked}
        >
          <span style={{ color: "white" }}>Confirm</span>
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};

interface ConfirmRecommendationsModalProps {
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
}

export default ConfirmRecommendationsModal;
