import { Divider, Drawer, Typography, useTheme } from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import DetailsViewDrawerHeader from "./DetailsViewDrawerHeader";
import DetailViewLabelValueItem from "./DetailViewLabelValueItem";
import moment from "moment";
import DetailViewAddress from "./DetailViewAddress";
import DetailsViewPatientCard from "./DetailsViewPatientCard";
import ItemsPaidForDrawer from "./ItemsPaidForDrawer";
import PrescriptionsView from "./PrescriptionsView";
import DetailsViewPatientIDImage from "./DetailsViewPatientIDImage";
import { useParams } from "react-router-dom";
import usePharmacyOrder from "../../hooks/usePharmacyOrder";
import { Spin } from "antd";

const PharmacyDetailsDrawer: FC<PharmacyDetailsDrawerProps> = ({ onClose }) => {
  // const history = useHistory<{ order?: PharmacyBookingModel }>();
  const urlParams = useParams<{ orderUUID: string }>();
  const [open, setOpen] = useState(false);
  const [showItemsDrawer, setShowItemsDrawer] = useState(false);
  const theme = useTheme();

  const { query } = usePharmacyOrder({ orderUUID: urlParams.orderUUID });

  const order = query.data?.pharmacyOrder;

  useEffect(() => {
    setTimeout(() => setOpen(true), 100);
  }, []);

  const createdAtMoment = useMemo(() => {
    return moment(order?.createdAt);
  }, [order?.createdAt]);

  const dispatchedMoment = useMemo(() => {
    return moment(order?.dispatchedAt);
  }, [order?.dispatchedAt]);

  const deliveredMoment = useMemo(() => {
    return moment(order?.deliveredAt);
  }, [order?.deliveredAt]);

  const items = useMemo(() => {
    const value = order?.availableItems ?? order?.items;
    return value ?? [];
  }, [order?.availableItems, order?.items]);

  const titleText = useMemo(() => {
    const text = items?.[0].dosage;
    if ((text?.length ?? 0) > 45) {
      return `${text?.slice(0, 45)}...`;
    }
    return text;
  }, [items]);

  const content = useMemo<React.ReactNode>(() => {
    if (!order?.orderUUID && query.isLoading) {
      return (
        <Spin spinning={true}>
          <div style={{ width: "100%", height: "100vh" }} />
        </Spin>
      );
    } else if (!order?.orderUUID) {
      return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", fontSize: "20px" }}>Something went wrong</div>
      );
    }
    return (
      <>
        <DetailsViewDrawerHeader order={order} />
        <Divider />
        <div style={{ padding: "16px 24px" }}>
          <div style={{ marginBottom: "16px" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "6px" }}>
              <Typography variant="h3">{titleText}</Typography>
              {/* <Button variant="outlined" onClick={() => setShowItemsDrawer(true)}>
                View All
              </Button> */}
            </div>
            <Typography variant="body2">{items[0].productName}</Typography>
          </div>
          <DetailViewLabelValueItem label={"Order ID"} value={order.orderUUID} />
          <DetailViewLabelValueItem label={"Received at"} value={createdAtMoment.format("DD/MM/YYYY hh:mm A")} />
          {order.dispatchedAt && (
            <DetailViewLabelValueItem label={"Dispatched at"} value={dispatchedMoment.format("DD/MM/YYYY hh:mm A")} />
          )}
          {order.deliveredAt && (
            <DetailViewLabelValueItem label={"Delivered at"} value={deliveredMoment.format("DD/MM/YYYY hh:mm A")} />
          )}
          <div style={{ marginTop: "16px" }}>
            {order.address && (
              <DetailViewAddress
                address={order.address}
                // lattitude={order.address?.lattitude ?? ""}
                // longitude={order.address?.longitude ?? ""}
                patientName={order.patient?.name ?? ""}
              />
            )}
          </div>
          {(order.prescriptions?.length ?? 0) > 0 && <PrescriptionsView prescriptions={order.prescriptions ?? []} />}
          {(order.patient?.images?.length ?? 0) > 0 && (
            <DetailsViewPatientIDImage images={order.patient?.images ?? []} patientName={order.patient?.name ?? ""} />
          )}
          {order.patient?._id && <DetailsViewPatientCard patients={[order.patient]} />}
        </div>
      </>
    );
  }, [createdAtMoment, deliveredMoment, dispatchedMoment, items, order, query.isLoading, titleText]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => onClose(), 100);
  }, [onClose]);

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor={"right"}
      elevation={0}
      sx={{
        "& .MuiBackdrop-root": { backgroundColor: "unset" },
        "& .MuiPaper-root": {
          borderLeft: `1px solid ${theme.components?.MuiDivider?.defaultProps?.style?.borderColor}`,
        },
      }}
    >
      <div style={{ width: "700px" }}>{content}</div>
      {order?.orderUUID && (
        <ItemsPaidForDrawer
          open={showItemsDrawer}
          onClose={function (): void {
            setShowItemsDrawer(false);
          }}
          order={order}
        />
      )}
    </Drawer>
  );
};

interface PharmacyDetailsDrawerProps {
  // order: PharmacyBookingModel;
  onClose: () => void;
}

export default PharmacyDetailsDrawer;
