import { FC } from "react";
import { PharmacyOrderItem } from "../../models/PharmacyBookingModel";
import { Box, Button, Divider, ListItemText } from "@mui/material";
import TransitionGroupList from "../TransitionGroupList";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";

const UnavailableItemsList: FC<UnavailableItemsListProps> = ({ items, handleAddAvailableItem }) => {
  return (
    <TransitionGroupList
      children={items.map((item, i) => {
        const element = (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <ListItemText primary={item.productName} />
              <Button variant="outlined" onClick={() => handleAddAvailableItem(item)} startIcon={<AddCircleOutlineRoundedIcon />}>
                Available
              </Button>
            </div>
            {i !== items.length - 1 && <Divider sx={{ width: "100%", marginTop: "1rem" }} />}
          </Box>
        );
        return { element: element, key: `${item.productName} - ${item.dosage}` };
      })}
    />
  );
};

interface UnavailableItemsListProps {
  items: PharmacyOrderItem[];
  handleAddAvailableItem: (item: PharmacyOrderItem) => void;
}

export default UnavailableItemsList;
