import { Collapse, List, ListItem } from "@mui/material";
import { FC } from "react";
import { TransitionGroup } from "react-transition-group";

const TransitionGroupList: FC<TransitionGroupListProps> = ({ children }) => {
  return (
    <List>
      <TransitionGroup>
        {children.map((item) => {
          return (
            <Collapse key={item.key}>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {item.element}
              </ListItem>
            </Collapse>
          );
        })}
      </TransitionGroup>
    </List>
  );
};

interface TransitionGroupListProps {
  children: { element: JSX.Element; key: string }[];
}

export default TransitionGroupList;
