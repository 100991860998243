import { FC, useCallback, useRef, useState } from "react";
// import Mixpanel from "../../utils/Mixpanel";
import RecommendationsButton from "./RecommendationsButton";
import { showMessage } from "../../components/common/notification";
import UploadFilePayloadModel from "../../models/UploadFilePayloadModel";
import convertFileToBase64 from "../../utils/convertFileToBase64";
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import { ConsultationRecommendationsInitialState } from "../../reducer/consultation.recommendations.reducer";
import { useDispatch, useSelector } from "react-redux";
import { ConsultationRecommendationsType } from "../../types";
import { Box, Typography } from "@mui/material";
import RecommendationsShowSelectedItem from "./RecommendationsShowSelectedItem";
import ReduxStateModel from "../../models/ReduxStateModel";
import RecommendationsCheckCircle from "./RecommendationsCheckCircle";

const SuggestionsUploadPrescriptionButton: FC = () => {
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);
  // const selectedPrescription = !!consultationRecommendationsReducer.prescription?.fileName;
  const dispatch = useDispatch();
  const didSelectPrescription = !!consultationRecommendationsReducer.prescription;
  // const { orderUUID } = useParams<{ orderUUID: string }>();
  const [file, setFile] = useState<UploadFilePayloadModel | undefined>(() => consultationRecommendationsReducer.prescription);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) {
        return console.log("--- pick file no files error");
      }
      const file = e.target.files[0];
      // setSelectedFile(e.target.files[0]);
      const base64 = await convertFileToBase64(file);
      if (!base64) {
        return showMessage("error", "Something went wrong");
      }
      const fileType = file?.type.toLocaleLowerCase().includes("pdf") ? "pdf" : "image";
      const fileUploadPayload: UploadFilePayloadModel = {
        data: fileType === "pdf" ? base64.slice(28) : base64,
        fileName: file?.name ?? "",
        type: file?.type ?? "",
        documentType: "PRESCRIPTION",
        fileType: fileType,
      };
      setFile(fileUploadPayload);
      const payload: Partial<ConsultationRecommendationsInitialState> = {
        prescription: fileUploadPayload,
      };
      dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    },
    [dispatch]
  );

  return (
    <Box sx={{ cursor: "pointer" }}>
      <RecommendationsButton
        title="Upload Prescription"
        trailingText={file ? "Re upload" : "Upload"}
        subtitle={
          didSelectPrescription ? (
            <Box display={"flex"} gap={1} alignItems={"center"} marginTop={0.5}>
              <RecommendationsCheckCircle selected={true} size="16px" iconSize="12px" />
              <Typography fontSize={"14px"} lineHeight={"1"}>
                Patient prescription uploaded
              </Typography>
            </Box>
          ) : (
            "Upload patient prescriptions for accurate treatment."
          )
        }
        bottom={
          <>
            {file && (
              <RecommendationsShowSelectedItem
                img={"https://dardocstorageaccount.blob.core.windows.net/dardocpictures/Framelaskdnfkasjbfkjsbdf101558.png"}
                title={file?.fileName ?? ""}
              />
            )}
            <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png, .pdf" ref={inputRef} onChange={handleChange} />
          </>
        }
        startIcon={
          <img
            src="https://dardocstorageaccount.blob.core.windows.net/dardocpictures/pills.circle.fill.png"
            alt="Upload Prescription"
            width={90}
            height={90}
            style={{ width: "30px", height: "30px" }}
          />
        }
        onClick={() => {
          inputRef.current?.click();
        }}
      />
    </Box>
  );
  //       <Link to={`/order/${orderUUID}/upload-prescription?isTRTBooking=true`}>
  // </Link>
};

export default SuggestionsUploadPrescriptionButton;
