import { FC, useMemo } from "react";
import { Typography } from "@mui/material";
import { AssignStaffDateModel } from "../models/BookingModel";
import { grey } from "@mui/material/colors";
import getStartMomentFromDateObj from "../utils/getStartMomentFromDateObj";

const AssignStaffDateSplitShiftTitle: FC<AssignStaffDateSplitShiftTitleProps> = ({ dateObj, isSecondShift }) => {
  const shiftStartMoment = useMemo(() => {
    if (isSecondShift) {
      if (!dateObj.firstShiftDuration) return "";
      return getStartMomentFromDateObj({ date: dateObj.date, time: dateObj.time })
        .add(dateObj.firstShiftDuration, "hours")
        .format("hh:mm A");
    }
    return getStartMomentFromDateObj({ date: dateObj.date, time: dateObj.time }).format("hh:mm A");
  }, [dateObj.date, dateObj.firstShiftDuration, dateObj.time, isSecondShift]);

  return (
    <Typography>
      {isSecondShift ? "Second shift: " : `First shift: `}
      <span style={{ color: grey[600] }}>{shiftStartMoment}</span>
    </Typography>
  );
};

interface AssignStaffDateSplitShiftTitleProps {
  dateObj: AssignStaffDateModel;
  isSecondShift: boolean;
}

export default AssignStaffDateSplitShiftTitle;
