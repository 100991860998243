import { List, ListItemText, ListItemButton, ListItem, Button, Typography, Box } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ConsultationCustomizationModel } from "../../models/GetProductsForConsultationResponseModel";
import { ConsultationRecommendationsType } from "../../types";
import { ConsultationRecommendationsInitialState } from "../../reducer/consultation.recommendations.reducer";
import ReduxStateModel from "../../models/ReduxStateModel";
import Mixpanel from "../../utils/Mixpanel";
import LoadingSpinner from "../../components/LoadingSpinner";
import SuggestionsModalDialog from "./SuggestionsModalDialog";
import RecommendationsCheckCircle from "./RecommendationsCheckCircle";

const SelectIVDripCustomizationsModal: FC<SelectIVDripCustomizationsModalProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const [selectedAddons, setSelectedAddons] = useState<ConsultationCustomizationModel[]>([]);

  const handleClose = useCallback(() => {
    history.push(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  const handleNextStep = useCallback(() => {
    if (!selectedAddons.length) {
      const payload: Partial<ConsultationRecommendationsInitialState> = {
        selectedCustomizations: [],
      };
      dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    }
    history.push(`/order/${orderUUID}/suggestions`);
  }, [dispatch, history, orderUUID, selectedAddons.length]);

  const handleContinueClicked = useCallback(() => {
    const payload: Partial<ConsultationRecommendationsInitialState> = {
      selectedCustomizations: selectedAddons,
    };
    dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    Mixpanel.track("SelectIVDripCustomizationsModal skip clicked", {
      ...payload,
      partnerUser: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    handleNextStep();
  }, [dispatch, handleNextStep, selectedAddons]);

  if (consultationRecommendationsReducer.selectedIVDrip === "SKIP") {
    console.log("--- selected iv drip is SKIP in customizations modal");
    return <Redirect to={`/order/${orderUUID}`} />;
  }

  return (
    <SuggestionsModalDialog open={true} onClose={handleClose}>
      <Typography display={"flex"} alignItems={"flex-end"} gap={1} marginBottom={2}>
        <Box
          fontWeight={600}
          letterSpacing={"-0.28px"}
          fontSize={"56px"}
          lineHeight={"60px"}
          color={"#1D1D1F"}
          sx={{
            "@media (max-width: 700px)": {
              fontSize: "30px",
            },
          }}
        >
          {consultationRecommendationsReducer.selectedIVDrip?.name}
        </Box>
      </Typography>
      <Typography letterSpacing={"0.228px"} color={"#59595B"} fontWeight={600} marginBottom={2.5}>
        Select Add-on(s)
      </Typography>
      <LoadingSpinner spinning={false} styles={{ minHeight: "100px" }}>
        <List
          dense
          sx={{
            // width: "100%",
            bgcolor: "#F7F7F7",
            borderRadius: "14px",
            height: "100%",
            // minHeight: "48vh",
            maxHeight: "48vh",
            overflow: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              backgroundColor: "unset",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#1D1D1F",
              borderRadius: "10px",
            },
          }}
        >
          {consultationRecommendationsReducer.selectedIVDrip?.customizations?.map((addon) => {
            return (
              <ListItem
                key={addon.customizationUUID}
                sx={{
                  "& .MuiListItemSecondaryAction-root .MuiButtonBase-root": {
                    minWidth: "50px",
                  },
                  "& .MuiButtonBase-root:hover": {
                    backgroundColor: "unset",
                  },
                  "& .MuiListItemButton-root": {
                    alignItems: "center",
                  },
                }}
                disablePadding
              >
                <ListItemButton
                  onClick={() => {
                    if (selectedAddons.some((ad) => ad.customizationUUID === addon.customizationUUID)) {
                      setSelectedAddons((value) => value.filter((ad) => ad.customizationUUID !== addon.customizationUUID));
                    } else {
                      setSelectedAddons((value) => [...value, addon]);
                    }
                  }}
                >
                  <ListItemText
                    id={addon.customizationUUID}
                    primary={
                      <Typography letterSpacing={"-0.41px"} width={"100%"} maxWidth={"200px"} variant="subtitle1">
                        {addon.engName}
                      </Typography>
                    }
                  />
                  <RecommendationsCheckCircle
                    selected={selectedAddons.some((item) => item.customizationUUID === addon.customizationUUID)}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </LoadingSpinner>
      <Button
        variant={"contained"}
        fullWidth={true}
        disableElevation={true}
        onClick={selectedAddons.length ? handleContinueClicked : handleNextStep}
        sx={{
          marginTop: 4,
          backgroundColor: "black",
          color: "white",
          borderRadius: "30px",
          "&:hover": {
            backgroundColor: "black",
          },
          "&.MuiButtonBase-root": {
            color: "white",
            fontWeight: 400,
          },
        }}
        size="large"
      >
        {selectedAddons.length ? "Continue" : "Skip"}
      </Button>
    </SuggestionsModalDialog>
  );
};

interface SelectIVDripCustomizationsModalProps {}

export default SelectIVDripCustomizationsModal;
