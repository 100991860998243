import { FC, useMemo } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import RecommendationsButton from "./RecommendationsButton";
import ReduxStateModel from "../../models/ReduxStateModel";
import RecommendationsCheckCircle from "./RecommendationsCheckCircle";
import ShowRecommendationsSelectedLabTests from "./ShowRecommendationsSelectedLabTests";

const SuggestionsLabTestButton: FC = () => {
  const { url } = useRouteMatch();
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);

  const didSelectLabTest = useMemo(() => {
    if (
      consultationRecommendationsReducer.selectedBuildYourLabTestItems !== "SKIP" &&
      consultationRecommendationsReducer.selectedBuildYourLabTestItems?.length
    ) {
      return true;
    }
    return (
      !!consultationRecommendationsReducer.selectedLabTests?.length &&
      consultationRecommendationsReducer.selectedLabTests !== "SKIP"
    );
  }, [consultationRecommendationsReducer.selectedBuildYourLabTestItems, consultationRecommendationsReducer.selectedLabTests]);

  return (
    <Link to={`${url}/lab-tests`}>
      <RecommendationsButton
        trailingText={didSelectLabTest ? "Edit" : "Select"}
        title="Suggest a Lab Test"
        subtitle={
          didSelectLabTest ? (
            <Box display={"flex"} gap={1} alignItems={"center"} marginTop={0.5}>
              <RecommendationsCheckCircle selected={true} size="16px" iconSize="12px" />
              <Typography fontSize={"14px"} lineHeight={"1"}>
                Lab Test selected
              </Typography>
            </Box>
          ) : (
            "Recommend the right lab tests tailored to your patient’s needs."
          )
        }
        startIcon={
          <img
            src="https://dardocstorageaccount.blob.core.windows.net/dardocpictures/testtube.2.png"
            alt="Suggest a Lab Test"
            width={90}
            height={90}
            style={{ width: "30px", height: "30px" }}
          />
        }
        bottom={didSelectLabTest ? <ShowRecommendationsSelectedLabTests /> : null}
      />
    </Link>
  );
};

export default SuggestionsLabTestButton;
