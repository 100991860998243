import { FC, useMemo } from "react";
import { Box, Checkbox, Divider, Paper, TextField, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import getStartMomentFromDateObj from "../utils/getStartMomentFromDateObj";
import BookingModel, { AssignStaffDateModel } from "../models/BookingModel";
import AssignStaffSelectFirstNurse from "./AssignStaffSelectFirstNurse";
import AssignStaffDateSplitShiftTitle from "./AssignStaffDateSplitShiftTitle";

const AssignStaffDate: FC<AssignStaffDateProps> = ({
  booking,
  dateObj,
  onFirstShiftDurationChanged,
  onSecondShiftDurationChanged,
  onSecondShiftChanged,
  onFirstShiftChanged,
  onSplitBookingChanged,
}) => {
  const startMoment = useMemo(() => {
    return getStartMomentFromDateObj({ date: dateObj.date, time: dateObj.time });
  }, [dateObj.date, dateObj.time]);

  const showInvalidDurationError = useMemo<boolean>(() => {
    if (!dateObj.splitShift) {
      return false;
    }
    const firstShiftDuration = dateObj.firstShiftDuration;
    const secondShiftDuration = dateObj.secondShiftDuration;
    if (isNaN(firstShiftDuration ?? Number("s"))) {
      return false;
    }
    if (isNaN(secondShiftDuration ?? Number("s"))) {
      return false;
    }
    if ((dateObj.firstShiftDuration ?? 0) + (dateObj.secondShiftDuration ?? 0) !== dateObj.numberOfDurationOrSession) {
      return true;
    }
    return false;
  }, [dateObj.firstShiftDuration, dateObj.numberOfDurationOrSession, dateObj.secondShiftDuration, dateObj.splitShift]);

  return (
    <Paper elevation={0} sx={{ padding: 2, width: "100%", border: "1px solid #CCC" }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography color={"grey"}>Date: </Typography>
        <Typography>{startMoment.format("DD/MM/YYYY dddd")}</Typography>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} marginTop={"8px"}>
        <Typography color={"grey"}>Time: </Typography>
        <Typography>{startMoment.format("hh:mm A")}</Typography>
      </Box>
      {booking.productType === "S-D" && (
        <Box display={"flex"} justifyContent={"space-between"} marginTop={"8px"}>
          <Typography color={"grey"}>Duration: </Typography>
          <Typography>{dateObj.numberOfDurationOrSession} Hours</Typography>
        </Box>
      )}
      {dateObj.numberOfDurationOrSession >= 12 && (
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography color={"grey"}>Split shift: </Typography>
          <Checkbox
            checked={dateObj.splitShift ?? false}
            onChange={(e) => {
              onSplitBookingChanged(e.target.checked);
            }}
          />
        </Box>
      )}
      {dateObj.splitShift && (
        <>
          <Divider sx={{ marginY: "16px" }} />
          {showInvalidDurationError && (
            <Typography color={red[500]} marginBottom={2} fontWeight={500}>
              Invalid shift durations
            </Typography>
          )}
          <AssignStaffDateSplitShiftTitle dateObj={dateObj} isSecondShift={false} />
        </>
      )}
      <AssignStaffSelectFirstNurse
        booking={booking}
        dateObj={dateObj}
        isSecondShift={false}
        label="Select staff"
        value={dateObj.firstShiftNurseUUID}
        onChange={function (nurseUUID: string): void {
          onFirstShiftChanged(nurseUUID);
        }}
      />
      {dateObj.splitShift && (
        <TextField
          variant="outlined"
          label="Duration (hours)"
          size="small"
          fullWidth={true}
          sx={{ marginTop: "16px" }}
          value={dateObj.firstShiftDuration ?? ""}
          onChange={(e) => {
            const value = e.target.value;
            const numberValue = +value;
            if (isNaN(numberValue)) {
              return;
            }
            onFirstShiftDurationChanged(numberValue);
          }}
        />
      )}
      {dateObj.splitShift && (
        <>
          <Divider sx={{ marginY: "16px" }} />
          <AssignStaffDateSplitShiftTitle dateObj={dateObj} isSecondShift={true} />
          <AssignStaffSelectFirstNurse
            id="assign-staff-select-second-nurse"
            dateObj={dateObj}
            isSecondShift={true}
            label="Select staff"
            booking={booking}
            value={dateObj.secondShiftNurseUUID}
            onChange={function (nurseUUID: string): void {
              onSecondShiftChanged(nurseUUID);
            }}
          />
          <TextField
            variant="outlined"
            label="Duration (hours)"
            size="small"
            fullWidth={true}
            sx={{ marginTop: "16px" }}
            value={dateObj.secondShiftDuration ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              const numberValue = +value;
              if (isNaN(numberValue)) {
                return;
              }
              onSecondShiftDurationChanged(numberValue);
            }}
          />
        </>
      )}
    </Paper>
  );
};

interface AssignStaffDateProps {
  dateObj: AssignStaffDateModel;
  booking: BookingModel;
  onSplitBookingChanged: (value: boolean) => void;
  onFirstShiftChanged: (nurseUUID: string) => void;
  onSecondShiftChanged: (nurseUUID: string) => void;
  onFirstShiftDurationChanged: (duration: number) => void;
  onSecondShiftDurationChanged: (duration: number) => void;
}

export default AssignStaffDate;
