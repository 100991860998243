import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BookingIncludedTestsList from "../../components/common/BookingsTable/BookingIncludedTestsList";
import TableCustomizationsList from "../../components/common/BookingsTable/TableCustomizationsList";
import LoadingSpinner from "../../components/LoadingSpinner";
import useOrderDetails from "../../hooks/useOrderDetails";
import PrescribeTRTMedicationButton from "./PrescribeTRTMedicationButton";
import AssignStaffModal from "../../components/AssignStaffModal";
import OrderDetailsPatientListItem from "./OrderDetailsPatientListItem";
import OrderRecommendations from "./OrderRecommendations";
import SuggestIVDripOrLabTestButton from "./SuggestIVDripOrLabTestButton";
import RecommendationsRoutes from "./RecommendationsRoutes";
import getStartMomentFromDateObj from "../../utils/getStartMomentFromDateObj";
import PatientInfoItem from "./PatientInfoItem";
import useCurrentUserRole from "../../hooks/useCurrentUserRole";
import useProductDetails from "../../hooks/useProductDetails";
import ShowBookingAssignedNurse from "../../components/common/BookingsTable/ShowBookingAssignedNurse";
import OrderMedicationsList from "./OrderMedicationsList";
import UploadBookingReportButton from "../../components/common/UploadBookingReportButton";
import SubscriptionBookingRibbon from "../../components/common/BookingsTable/SubscriptionBookingRibbon";
import AcknowledgeDeliveryBooking from "../../components/common/BookingsTable/AcknowledgeDeliveryBooking";
// import UploadWLPPrescriptionsButton from "./UploadWLPPrescriptionsButton";
import MarkVideoConsultationCompleteModal from "./MarkVideoConsultationCompleteModal";
import NewAssignNurseModal from "../../components/common/BookingsTable/NewAssignNurseModal";
import BookingReferenceDocs from "./BookingReferenceDocs";
import ModalPopUp from "../../components/common/BookingsTable/Modal";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { TiCancel } from "react-icons/ti";
import timeFormatter from "../../components/common/timeFormat";
import encrypt from "../../services/encrypt";
import { Button, CircularProgress, Modal, Avatar, Chip, Typography, Box } from "@mui/material";
import { MdCancel, MdOutlineVerifiedUser } from "react-icons/md";
import { FaClipboardCheck, FaUserNurse } from "react-icons/fa";
import { BsClockHistory, BsCalendar3 } from "react-icons/bs";
import { FiCheckCircle, FiCheckSquare } from "react-icons/fi";
import { IoDocumentAttachSharp, IoDocumentAttach } from "react-icons/io5";
import moment from "moment";
import { nurseAction } from "../../actions";
import { Empty, Tooltip, Steps } from "antd";
import { BiTimeFive } from "react-icons/bi";
import { orderAction } from "../../actions";
import { Row, Col } from "antd";
import AcknowledgeBookingButton from "./AckowledgeBookingButton";
import { wlpLabResultConsultationProductUUID } from "../../constants";
import StartCallButton from "./StartCallButton";
import BasePageLayout from "../../components/BasePageLayout";
import "./style.css";
import { useParams } from "react-router-dom";
import BookingModel, {
  BookingActivityDateItemModel,
  BookingActivityDateItemValueModel,
  BookingDateModel,
} from "../../models/BookingModel";
import ReduxStateModel from "../../models/ReduxStateModel";
import { GetOrderDetailsResponseModel } from "../../services";

// const useStyles = makeStyles((theme) => ({
//   small: {
//     width: theme.spacing(3),
//     height: theme.spacing(3),
//     border: "1px solid #e0e0e0",
//   },
// }));

const { Step } = Steps;

const trtVideoConsultationProductUUID = "dcb437b3-e40a-437c-9dc1-cc90afe5425a";

function ShowOrder() {
  const role = localStorage?.role ? JSON?.parse(localStorage?.role) : "";
  const dispatch = useDispatch();
  const [order, setorder] = useState<BookingModel>({});
  const [openModalConfirmApply, setopenModalConfirmApply] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openActivityModal, setopenActivityModal] = useState(false);
  const [page, setpage] = useState(1);
  const [startRange, setstartRange] = useState(0);
  const [endRange, setendRange] = useState(7);
  const [activityDate, setactivityDate] = useState("");
  const [reportIndex, setreportIndex] = useState({});
  const [showMarkVideoConsultationCompleteModal, setShowMarkVideoConsultationCompleteModal] = useState(false);
  const [showSplitBookingModal, setShowSplitBookingModal] = useState(false);

  const orderLoader = useSelector((state: ReduxStateModel) => state?.orderReducer?.singleOrderLoader);
  const [openNurseAssignModal, setopenNurseAssignModal] = useState(false);

  const nurses = useSelector((state: ReduxStateModel) => state?.nurseReducer?.nurses);

  const { query: productDetailsQuery } = useProductDetails({
    area: order?.emirate,
    productUUID: order?.patientId?.length ? order?.patientId[0]?.productUUID : "",
  });
  const { orderUUID } = useParams<{ orderUUID: string }>();

  const { query: orderDetailsQuery } = useOrderDetails({
    orderUUID: orderUUID,
    onSuccess: (res) => {
      if (res?.order) {
        setorder(res.order);
      }
    },
  });

  useEffect(() => {
    if (orderDetailsQuery?.data?.order) {
      setorder(orderDetailsQuery?.data?.order);
    }
  }, [orderDetailsQuery?.data?.order]);

  // const orderData = useSelector((state) => state.orderReducer.singleOrder);

  const openNurseModal = (data: BookingModel) => {
    dispatch(nurseAction.fetchValidNursesForAnOrder(data?.orderUUID));
    if ((order?.patientId?.length ?? 0) > 1) {
      setopenNurseAssignModal(true);
    } else {
      setShowSplitBookingModal(true);
    }
  };

  const editorder = (orderData: BookingModel) => {
    if (orderData?.orderUUID) {
      const tempdata = orderData;
      const temppatients = orderData?.patient ?? [];
      const tempatientarray = [];
      for (let i = 0; i < (temppatients?.length ?? 0); i++) {
        if (i === 0) {
          tempatientarray.push({
            ...temppatients[i],
            speciality: [
              {
                type: temppatients[i]?.type,
                variantName: temppatients[i]?.variantName ? temppatients[i]?.variantName : "",
              },
            ],
          });
        } else {
          if (temppatients[i]?.name === tempatientarray[tempatientarray?.length - 1]?.name) {
            tempatientarray[tempatientarray?.length - 1].speciality.push({
              type: temppatients[i]?.type,
              variantName: temppatients[i]?.variantName ? temppatients[i]?.variantName : "",
            });
          } else {
            tempatientarray.push({
              ...temppatients[i],
              speciality: [
                {
                  type: temppatients[i]?.type,
                  variantName: temppatients[i]?.variantName ? temppatients[i]?.variantName : "",
                },
              ],
            });
          }
        }
      }

      tempdata.patient = tempatientarray;

      if ((tempdata?.date?.length ?? 0) > 1) {
        const latestDateOrderArray = (dates: BookingDateModel[]) => {
          if (dates?.length > 0) {
            const mysorteddata = dates.sort((a: BookingDateModel, b: BookingDateModel) => {
              const c = moment(a.date, "DD/MM/YYYY");
              const d = moment(b.date, "DD/MM/YYYY");
              return moment(c).diff(d);
            });
            return mysorteddata;
          }
        };

        tempdata.date = latestDateOrderArray(tempdata.date ?? []);
      }
      //getImages(tempdata);
      setorder(tempdata);
    } else {
      const loacalOrder = JSON?.parse(localStorage.order);
      if (loacalOrder?.orderUUID) {
        setorder(loacalOrder);
        if (loacalOrder?.patient?.length) {
          //getImages(loacalOrder);
        }
      }
    }
  };

  const getNurseUUID = () => {
    const nurseUUID = order?.assignedNurses?.[0]?.nurseUUID;
    if (!nurseUUID) return "";
    const enkeyID = encrypt(nurseUUID);
    const nurse = enkeyID.toString().replaceAll("+", "xMl3Jk").replaceAll("/", "Por21Ld").replaceAll("=", "Ml32");
    return nurse;
  };

  const getActivityTitle = (activityData: { nurseUUID?: string; timestamp?: string }) => {
    if (nurses && nurses.length > 0) {
      const nursepresent = nurses.findIndex((nurse) => nurse.nurseUUID === activityData?.nurseUUID);
      const getNurseName = (fname: string, lname: string) => {
        const name = `${fname} ${lname}`;
        return name;
      };

      if (nursepresent >= 0) {
        return (
          <Row
            style={{
              textTransform: "capitalize",
              alignItems: "center",
              columnGap: "2%",
            }}
          >
            <Col>
              <Avatar alt="" src={nurses[nursepresent]?.profileImage} />
            </Col>
            <Col>
              <p
                style={{
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                {getNurseName(nurses[nursepresent]?.firstName ?? "", nurses[nursepresent]?.lastName ?? "")}
              </p>
            </Col>
            <Col>
              <p
                style={{
                  color: "rgba(0,0,0,0.6)",
                  margin: 0,
                }}
              >
                {moment(activityData?.timestamp).format("DD MMM YYYY HH:mm")}
              </p>
            </Col>
          </Row>
        );
      }

      return (
        <Row
          style={{
            textTransform: "capitalize",
            alignItems: "center",
            columnGap: "2%",
          }}
        >
          <Col>
            <Avatar alt="" src={""} />
          </Col>

          <Col>
            <p
              style={{
                fontWeight: "bold",
                margin: 0,
              }}
            >
              Nurse deleted
            </p>
          </Col>

          <Col>
            <p
              style={{
                margin: 0,
              }}
            >
              {moment(activityData?.timestamp).format("DD MMM YYYY hh:mmA")}
            </p>
          </Col>
        </Row>
      );
    }
  };

  const getListValue = (valueArray: BookingActivityDateItemValueModel[]) => {
    const valueTemp = [];
    for (let i = 0; i < valueArray?.length; i++) {
      valueTemp.push(valueArray[i]?.value);
    }
    return valueTemp?.join(", ");
  };

  const getActivityDescription = (activityData: BookingActivityDateItemModel) => {
    return (
      <div style={{ marginBottom: "1rem" }}>
        <div style={{ marginBottom: "6px", fontWeight: "bold" }}>{activityData?.engActivityName}</div>
        {activityData?.reportOptionType === "NONE" ? null : activityData?.reportOptionType === "TEXT" ? (
          <div style={{ marginBottom: "6px" }}>{activityData?.value?.[0]?.value}</div>
        ) : activityData?.reportOptionType === "MEASUREMENT" ? (
          <div style={{ marginBottom: "6px" }}>
            {activityData?.value?.[0]?.value}&nbsp;
            {activityData?.value?.[0]?.type}
          </div>
        ) : activityData?.reportOptionType === "DROPLIST" ? (
          <div style={{ marginBottom: "6px" }}>
            {(activityData?.value?.length ?? 0) > 1 ? getListValue(activityData?.value ?? []) : activityData?.value?.[0]?.value}
          </div>
        ) : null}
      </div>
    );
  };

  const handleActivityModalOpen = (date: string) => {
    setactivityDate(date);
    setopenActivityModal(true);
  };

  const handleActivityModalClose = () => {
    setactivityDate("");
    setopenActivityModal(false);
  };

  const handleModalClose = () => {
    setreportIndex({});
    setOpenModal(false);
  };

  const handleConfirmApplyModalOpen = () => {
    if (order?.patientId?.[0]?.productUUID === labResultsConsultationProductUUUD && !order?.isOtpVerified) {
      setShowMarkVideoConsultationCompleteModal(true);
    } else {
      setopenModalConfirmApply(true);
    }
  };

  const handleConfirmApplyModalClose = () => {
    setopenModalConfirmApply(false);
  };

  const handleReportingBtnAction = () => {
    handleConfirmApplyModalClose();
    const handleOrderUpdate = () => {
      const handlegetpatientSuccess = async (res: { data: GetOrderDetailsResponseModel }) => {
        editorder(res?.data?.order ?? {});
        setorder(res?.data?.order ?? {});
      };
      dispatch(orderAction.getIndividualOrder(order?.orderUUID, handlegetpatientSuccess));
      setorder((value) => ({ ...value, status: "REPORTED" }));
    };
    dispatch(
      orderAction.orderResult(
        {
          reports: ["reports"],
          reportingOrder: order?.orderUUID,
          reportingPatient: order?.patient?.[0]?._id,
          reportingPriceId: order?.patientId?.[0]?.priceId,
          isDarLabProduct: order?.patientId?.[0]?.isDarLabProduct,
          partnerUserUUID: JSON?.parse(localStorage.partnerUserUUID),
        },
        handleOrderUpdate,
        "Booking was completed successfully"
      )
    );
  };

  const handleAssignNurseSuccess = (orderUUID: string) => {
    dispatch(
      orderAction.getIndividualOrder(orderUUID, (res: { data: GetOrderDetailsResponseModel }) => {
        if (res?.data?.order?.orderUUID) {
          editorder(res?.data?.order);
        }
      })
    );
  };

  const nurseChange = (booking: BookingModel) => {
    setorder(booking);
    handleAssignNurseSuccess(booking?.orderUUID ?? "");
  };

  const getDateIsNotPast = (dateString: string) => {
    if (dateString === "") {
      return true;
    }
    const pastDate = moment(dateString, "DD/MM/YYYY").format("DD/MM/YYYY");

    if (pastDate === moment().format("DD/MM/YYYY")) {
      return true;
    }
    if (moment().isBefore(moment(pastDate, "DD/MM/YYYY"))) {
      return true;
    } else {
      return false;
    }
  };

  const getShowActivity = () => {
    if (order?.activity) {
      const alternateDate = moment(activityDate, "DD/MM/YYYY").format("DD-MM-YYYY");
      if (order?.activity[0][activityDate]?.length || order?.activity[0][alternateDate]?.length) {
        const activityList: BookingActivityDateItemModel[] = [];
        for (let i = 0; i < order?.activity[0][activityDate]?.length; i++) {
          if (order?.activity[0][activityDate][i]?.activities?.length) {
            activityList.push(...(order?.activity[0][activityDate][i]?.activities ?? []));
          }
        }

        for (let i = 0; i < order?.activity[0][alternateDate]?.length; i++) {
          if (order?.activity[0][alternateDate][i]?.activities?.length) {
            activityList.push(...(order?.activity[0][alternateDate][i]?.activities ?? []));
          }
        }
        if (activityList?.length) {
          return (
            <div className="steps-div">
              <Steps progressDot direction="vertical">
                {activityList
                  .filter((act) => {
                    const match = moment(act?.timestamp).format("DD/MM/YYYY") === activityDate;
                    return match;
                  })
                  ?.map((act, index) => (
                    <Step
                      key={index}
                      className="stem-item-amer"
                      status="finish"
                      title={getActivityTitle(act)}
                      description={getActivityDescription(act)}
                    />
                  ))}
              </Steps>
            </div>
          );
        }
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
      }
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  const getNurseActivity = (date: string) => {
    if (order?.activity) {
      if (order?.activity[0][`${date}`]) {
        return true;
      }
      if (order?.activity[0][`${moment(date, "DD/MM/YYYY").format("DD-MM-YYYY")}`]) {
        return true;
      }
    }
    return false;
  };

  const getDisablebtn = () => {
    if (!order?.patientId?.[0]?.isDarLabProduct) {
      const today = moment();
      const atime = order?.date?.[order?.date?.length - 1]?.time?.replace(/ /g, "");
      const splittedATime = atime?.split("-") ?? [];
      const orderTime = moment(order?.date?.[order?.date?.length - 1]?.date + "" + splittedATime[1], "DD/MM/YYYY hh:mmA")
        .subtract(30, "minute")
        .format("YYYY/MM/DD LT");

      const diffDarview = today.diff(orderTime);

      const sign = Math.sign(diffDarview);

      if (sign === -1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const getTitle = () => {
    if (getDisablebtn()) {
      return "Mark Complete will be enabled once the last schedule is completed";
    } else {
      return "Mark the booking as Complete";
    }
  };
  const getTotalHrs = (date: BookingDateModel[]) => {
    let totalHrs = 0;
    for (let i = 0; i < date?.length; i++) {
      totalHrs = totalHrs + (date[i]?.numberOfDurationOrSession ?? 0);
    }
    return totalHrs;
  };

  // useEffect(() => {
  //   editorder(orderData);
  // }, [orderData]);

  const markCompletedButtonText = useMemo(() => {
    if (order?.patientId?.[0]?.productUUID === labResultsConsultationProductUUUD && !order?.isOtpVerified) {
      return "Verify User";
    } else {
      return "Mark Complete";
    }
  }, [order?.isOtpVerified, order?.patientId]);

  const { canUpdate } = useCurrentUserRole();

  const showMarkCompleteButton = useMemo(() => {
    if (!canUpdate) return false;
    if (order?.status === "REPORTED") return false;
    if (order?.productType === "S-D") return false;
    if (productDetailsQuery?.isLoading) return false;
    if (productDetailsQuery?.data?.product?.isDarLabProduct) return false;
    const productUUID = order?.patientId?.[0]?.productUUID;
    if (productUUID === trtVideoConsultationProductUUID) return false;
    if (productUUID === "6d1b41e6-cb41-4427-8edd-d46ecea45702") return false;
    if (productUUID !== wlpLabResultConsultationProductUUID) return true;
    if (productUUID === wlpLabResultConsultationProductUUID && !order?.isPrescriptionUploaded) {
      return false;
    }
    if (!order?.patient?.[0]?.isDarLabProduct && order?.status === "PROCESSING") return true;
    return false;
  }, [
    canUpdate,
    order?.isPrescriptionUploaded,
    order?.patient,
    order?.patientId,
    order?.productType,
    order?.status,
    productDetailsQuery?.data?.product?.isDarLabProduct,
    productDetailsQuery?.isLoading,
  ]);

  // const showUploadPrescriptionButton = useMemo(() => {
  //   // return true;
  //   if (order.status !== "PROCESSING") return false;
  //   const productUUID = order?.patientId?.[0]?.productUUID;
  //   if (productUUID === wlpLabResultConsultationProductUUID) return true;
  //   return false;
  // }, [order]);

  // if (!orderData?.orderUUID) {
  //   return <Redirect to={"/"} />;
  // }

  return (
    <BasePageLayout
      mainContent={
        orderDetailsQuery.isLoading ? (
          <LoadingSpinner
            children={<span />}
            spinning={true}
            styles={{ minHeight: "100px", width: "100%" }}
            showBackdrop={false}
          />
        ) : !orderDetailsQuery.data?.order?.orderUUID ? (
          <Typography padding={4} width={"100%"} textAlign={"center"}>
            Something went wrong
          </Typography>
        ) : (
          <>
            <div className="">
              <div
                className="main_page"
                style={{
                  flex: "1 1",
                  height: "100%",
                  padding: "1rem",
                  overflowY: "scroll",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {orderLoader || orderDetailsQuery.isLoading || (!order?.orderUUID && orderDetailsQuery.isFetching) ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        padding: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "0.5rem",
                        height: "max-content",
                        padding: "1rem",
                      }}
                    >
                      {order?.orderUUID ? (
                        <div>
                          <Row
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                              rowGap: "1rem",
                            }}
                          >
                            <Col xs={24} md={12}>
                              <Row
                                style={{
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <Col>
                                  <h3
                                    style={{
                                      fontWeight: "bold",
                                      margin: 0,
                                    }}
                                  >
                                    <span>{order?.orderUUID}</span>
                                    <span
                                      style={{
                                        color: productDetailsQuery?.data?.product?.isDarLabProduct
                                          ? "rgb(0, 134, 144)"
                                          : undefined,
                                      }}
                                    >
                                      {" "}
                                      - {order?.patientId?.[0]?.type}
                                    </span>
                                  </h3>
                                </Col>
                                <Col>
                                  {order?.status === "CANCELLED" ? (
                                    <h3 style={{ margin: 0 }}>
                                      <Chip
                                        size={"small"}
                                        label={
                                          <Row
                                            gutter={8}
                                            style={{
                                              fontWeight: "bold",
                                              display: "flex",
                                              justifyContent: "flex-start",
                                              alignItems: "center",
                                              margin: 0,
                                            }}
                                          >
                                            <Col>
                                              <TiCancel size="26" color="#e86956" />
                                            </Col>
                                            <Col>
                                              <span>Cancelled</span>
                                            </Col>
                                          </Row>
                                        }
                                        variant="outlined"
                                      />
                                    </h3>
                                  ) : order?.status === "ACCEPTED" ? (
                                    <h3 style={{ margin: 0 }}>
                                      <Chip
                                        size={"small"}
                                        label={
                                          <Row
                                            gutter={8}
                                            style={{
                                              fontWeight: "bold",
                                              justifyContent: "flex-start",
                                              alignItems: "center",
                                              margin: 0,
                                            }}
                                          >
                                            <Col>
                                              <FaClipboardCheck color="#006400" />
                                            </Col>
                                            <Col>Accepted</Col>
                                          </Row>
                                        }
                                        variant="outlined"
                                      />
                                    </h3>
                                  ) : (order?.status === "PROCESSING" || order?.status === "PARTIALLY_REPORTED") &&
                                    order?.isSampleCollected ? (
                                    <h3 style={{ margin: 0 }}>
                                      <Chip
                                        size={"small"}
                                        label={
                                          <Row
                                            gutter={8}
                                            style={{
                                              fontWeight: "bold",
                                              justifyContent: "flex-start",
                                              alignItems: "center",
                                              margin: 0,
                                              gap: "0.5rem",
                                            }}
                                          >
                                            <IoDocumentAttachSharp color="#e86956" />
                                            <div>Sample Collected</div>
                                          </Row>
                                        }
                                        variant="outlined"
                                      />
                                    </h3>
                                  ) : order?.status === "PROCESSING" &&
                                    order?.assignedNurses &&
                                    (order?.assignedNurses?.length ?? 0) > 0 ? (
                                    <h3 style={{ margin: 0 }}>
                                      <Chip
                                        size={"small"}
                                        label={
                                          <Row
                                            gutter={8}
                                            style={{
                                              fontWeight: "bold",
                                              display: "flex",
                                              justifyContent: "flex-start",
                                              alignItems: "center",
                                              margin: 0,
                                            }}
                                          >
                                            <Col>
                                              <FaUserNurse color="#ffb803" />
                                            </Col>
                                            <Col>Assigned</Col>
                                          </Row>
                                        }
                                        variant="outlined"
                                      />
                                    </h3>
                                  ) : order?.status === "REPORTED" ? (
                                    !order?.patient?.[0]?.isDarLabProduct ? (
                                      <h3 style={{ margin: 0 }}>
                                        <Chip
                                          size={"small"}
                                          label={
                                            <Row
                                              gutter={8}
                                              style={{
                                                fontWeight: "bold",
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                margin: 0,
                                                gap: "4px",
                                              }}
                                            >
                                              <FiCheckCircle color="#008690" />
                                              <Col>Booking Complete</Col>
                                            </Row>
                                          }
                                          variant="outlined"
                                        />
                                      </h3>
                                    ) : (
                                      <h3 style={{ margin: 0 }}>
                                        <Chip
                                          size={"small"}
                                          label={
                                            <Row
                                              gutter={8}
                                              style={{
                                                fontWeight: "bold",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                margin: 0,
                                              }}
                                            >
                                              <Col>
                                                <IoDocumentAttach size="15" color="#008690" />
                                              </Col>
                                              <Col>Reported</Col>
                                            </Row>
                                          }
                                          variant="outlined"
                                        />
                                      </h3>
                                    )
                                  ) : (
                                    <></>
                                  )}

                                  {order?.isPremiumOrder ? (
                                    <h3 style={{ margin: 0 }}>
                                      <Chip
                                        size={"small"}
                                        label={
                                          <Row
                                            style={{
                                              color: "#000000",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Col>
                                              <MdOutlineVerifiedUser
                                                color="#25bcbd"
                                                size={20}
                                                style={{ marginRight: "0.2rem" }}
                                              />
                                            </Col>
                                            <Col>Premium</Col>
                                          </Row>
                                        }
                                        variant="outlined"
                                        style={{
                                          color: "white",
                                          marginLeft: "0.6rem",
                                        }}
                                      />
                                    </h3>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                                {order?.isUpgraded && (
                                  <Col>
                                    <div className="upgraded-to-clinical-chip" style={{ marginLeft: "0.6rem" }}>
                                      <h3 style={{ margin: 0 }}>
                                        <Chip
                                          size={"small"}
                                          label={
                                            <Row
                                              style={{
                                                fontWeight: "bold",

                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                margin: 0,
                                              }}
                                            >
                                              <Col
                                                style={{
                                                  color: "rgb(0, 100, 0)",
                                                }}
                                              >
                                                <BsFillPatchCheckFill />
                                              </Col>
                                              <Col>Upgraded to RN</Col>
                                            </Row>
                                          }
                                          variant="outlined"
                                        />
                                      </h3>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                            <Col>
                              <Row
                                style={{
                                  gap: "16px",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                {order?.orderUUID && <PrescribeTRTMedicationButton order={order} />}
                                {/* <UploadWLPPrescriptionsButton booking={order} setBooking={setorder} /> */}
                                {order?.orderUUID && <SuggestIVDripOrLabTestButton order={order} />}
                                <UploadBookingReportButton
                                  booking={order}
                                  setBooking={setorder}
                                  onReportedUploadedSuccess={null}
                                />
                                <AcknowledgeDeliveryBooking height="35px" booking={order} setBooking={setorder} />
                                <AcknowledgeBookingButton booking={order} setBooking={setorder} />
                                {order?.status !== "ACCEPTED" && role !== "ANALYST" && (
                                  <div className="doNotPrintThis">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <>
                                        {showMarkCompleteButton && (
                                          <Tooltip title={getTitle()} placement="bottom">
                                            <div>
                                              <Button
                                                variant={"contained"}
                                                color={"primary"}
                                                size="medium"
                                                disableElevation={true}
                                                style={{
                                                  width: "160px",
                                                  color: "#fff",
                                                }}
                                                disabled={getDisablebtn()}
                                                onClick={handleConfirmApplyModalOpen}
                                              >
                                                {markCompletedButtonText}
                                              </Button>
                                            </div>
                                          </Tooltip>
                                        )}
                                      </>
                                    </div>
                                  </div>
                                )}
                                {order?.isSubscriptionOrder ? <SubscriptionBookingRibbon /> : <></>}
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              margin: "1rem 0",
                              rowGap: "1rem",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Col xs={24} sm={24} md={24} lg={18}>
                              {!order?.patient?.[0]?.isDarLabProduct ? (
                                <div>
                                  {order?.productType === "S-D" && (
                                    <div
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        display: "flex",
                                        fontSize: "16px",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <BsCalendar3 size="18" color="#008690" />
                                      &nbsp;&nbsp;{order?.date?.length} {order?.productType === "S-D" ? "Days" : "Sessions"}
                                      &nbsp;-&nbsp;{getTotalHrs(order?.date ?? [])}
                                      &nbsp; Hours
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <Row
                                  style={{
                                    justifyContent: "flex-start",
                                    rowGap: "1rem",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Col xs={24} sm={7} md={7} lg={7} xl={6}>
                                    <Row
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        columnGap: "5%",
                                        margin: 0,
                                      }}
                                    >
                                      <BsCalendar3 size="18" color="#008690" />
                                      <p style={{ margin: 0 }}>{order?.date?.[0].date}</p>
                                    </Row>
                                  </Col>
                                  <Col xs={24} sm={7} md={7} lg={7} xl={6}>
                                    <Row
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                        columnGap: "5%",
                                        margin: 0,
                                      }}
                                    >
                                      <BiTimeFive
                                        size="22"
                                        color="#008690"
                                        style={{
                                          marginTop: "-3px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                      <p style={{ margin: 0 }}>{timeFormatter(order)}</p>
                                    </Row>
                                  </Col>
                                  {order?.isOrderDelayed && (
                                    <Col xs={24} sm={7} md={7} lg={7} xl={6}>
                                      <Row
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          color: "#e86956",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          columnGap: "5%",
                                          margin: 0,
                                        }}
                                      >
                                        <Col>
                                          <BsClockHistory size="18" style={{ marginTop: "-3px" }} />
                                        </Col>
                                        <Col>
                                          <p style={{ margin: 0 }}>Delayed</p>
                                        </Col>
                                      </Row>
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                              {order?.orderUUID && order?.productType === "S-P" && (
                                <ShowBookingAssignedNurse booking={order} onAssignNurseClicked={() => openNurseModal(order)} />
                              )}
                            </Col>
                          </Row>
                          <div
                            style={{
                              marginBottom: "1rem",
                              width: "100%",
                            }}
                          >
                            {!order?.patient?.[0]?.isDarLabProduct && (
                              <>
                                <Row
                                  style={{
                                    width: "100%",
                                    justifyContent: "flex-start",
                                    alignItems: "stretch",
                                    gap: "1rem",
                                  }}
                                >
                                  {(order?.date?.length ?? 0) > 0
                                    ? order?.date?.map((slot, index) => {
                                        if (index >= startRange && index <= endRange) {
                                          const startTime = getStartMomentFromDateObj({
                                            date: order?.date?.[index]?.date ?? "",
                                            time: order?.date?.[index]?.time ?? "",
                                          });
                                          return (
                                            <Col
                                              xs={24}
                                              sm={11}
                                              md={24}
                                              lg={11}
                                              xl={7}
                                              key={index}
                                              style={{
                                                // margin: "10px",
                                                cursor: `${getNurseActivity(slot?.date) ? "pointer" : "default"}`,
                                                backgroundColor: "#ffffff",
                                                padding: "10px",
                                                borderRadius: "8px",
                                                boxShadow: " 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 4px 6px 0 rgba(0, 0, 0, 0.12)",
                                              }}
                                              onClick={() => {
                                                if (getNurseActivity(slot?.date)) {
                                                  handleActivityModalOpen(slot?.date);
                                                }
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  gap: "1rem",
                                                }}
                                              >
                                                <PatientInfoItem
                                                  name="Date"
                                                  marginBottom="0px"
                                                  value={
                                                    <div>
                                                      <span>{slot?.date}&nbsp;&nbsp;</span>
                                                      <span>
                                                        {!getDateIsNotPast(slot?.date) ? (
                                                          <FiCheckSquare color="green" />
                                                        ) : order?.isOrderDelayed &&
                                                          slot?.date === moment().format("DD/MM/YYYY") ? (
                                                          <span>
                                                            <div
                                                              style={{
                                                                fontWeight: "bold",
                                                                color: "#e86956",
                                                                display: "flex",
                                                                justifyContent: "flex-start",
                                                                alignItems: "center",
                                                              }}
                                                            >
                                                              <BsClockHistory size="14" />
                                                              &nbsp;Delayed
                                                            </div>
                                                          </span>
                                                        ) : null}
                                                      </span>
                                                    </div>
                                                  }
                                                />
                                                <PatientInfoItem
                                                  name={order?.isDeliveryOrder ? "Time" : "Start time"}
                                                  value={startTime.format("HH:mm")}
                                                  marginBottom="0px"
                                                />
                                                {!order?.isDeliveryOrder && order?.productType === "S-D" && (
                                                  <PatientInfoItem
                                                    name="Duration"
                                                    marginBottom="0px"
                                                    value={`${slot?.numberOfDurationOrSession} ${order?.unitType ?? ""}`}
                                                  />
                                                )}
                                                {!order?.isDeliveryOrder && slot?.startTimestamp && (
                                                  <PatientInfoItem
                                                    name="Service Started"
                                                    marginBottom="0px"
                                                    value={moment(slot?.startTimestamp).format("DD MMM hh:mm A")}
                                                  />
                                                )}
                                                {getNurseActivity(slot?.date) && (
                                                  <Box
                                                    style={{
                                                      color: getNurseActivity(slot?.date) ? "#008690" : "",
                                                    }}
                                                  >
                                                    View Activity
                                                  </Box>
                                                )}
                                                {getDateIsNotPast(slot?.date) && (
                                                  <StartCallButton
                                                    booking={order}
                                                    startTime={startTime}
                                                    userUUID={getNurseUUID()}
                                                  />
                                                )}
                                              </Box>
                                            </Col>
                                          );
                                        }
                                        return null;
                                      })
                                    : null}
                                </Row>
                                <div className="doNotPrintThis">
                                  {(order?.date?.length ?? 0) > 8 && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        // paddingLeft: "1rem",
                                        // paddingRight: "1rem",
                                        paddingTop: "1rem",
                                      }}
                                    >
                                      <Button
                                        style={{
                                          borderRadius: "1.4rem",
                                          height: "30px",
                                          width: "150px",
                                          color: "#fff",
                                        }}
                                        color="primary"
                                        variant="contained"
                                        disabled={page === 1 ? true : false}
                                        onClick={() => {
                                          setpage(page - 1);
                                          setstartRange(startRange - 8);
                                          setendRange(endRange - 8);
                                        }}
                                      >
                                        <span
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {"<"}&nbsp;Previous
                                        </span>
                                      </Button>
                                      <Button
                                        style={{
                                          borderRadius: "1.4rem",
                                          height: "30px",
                                          width: "150px",
                                          color: "#fff",
                                        }}
                                        disabled={page >= (order?.date?.length ?? 0) / 8 ? true : false}
                                        onClick={() => {
                                          setpage(page + 1);
                                          setstartRange(startRange + 8);
                                          setendRange(endRange + 8);
                                        }}
                                        color="primary"
                                        variant="contained"
                                      >
                                        <span
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          Next &nbsp;{">"}
                                        </span>
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          <Box display={"flex"} gap={2} alignItems={"flex-start"}>
                            {order?.emirate && (
                              <BookingIncludedTestsList
                                area={order.emirate}
                                productUUID={order.patientId?.[0]?.productUUID ?? ""}
                              />
                            )}
                            {order?.orderUUID && <TableCustomizationsList customizations={order?.customizations ?? []} />}
                          </Box>
                          <div style={{ marginTop: "24px" }}>
                            {(order?.patient?.length ?? 0) > 0 &&
                              order?.patient?.map((patient, i) => (
                                <OrderDetailsPatientListItem
                                  key={patient._id}
                                  order={order}
                                  patient={patient}
                                  index={i}
                                  setorder={setorder}
                                  openNurseModal={openNurseModal}
                                />
                              ))}
                          </div>
                        </div>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                      {!order?.isDeliveryOrder && order?.referenceDocs?.length && <BookingReferenceDocs booking={order} />}
                      {order?.orderUUID && <OrderMedicationsList booking={order} />}
                      {order?.orderUUID && <OrderRecommendations order={order} />}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Modal
              open={openModalConfirmApply}
              onClose={handleConfirmApplyModalClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: "1rem",
                  padding: "2rem",
                  width: "max-content",
                  height: "max-content",
                }}
              >
                <h2
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Complete booking?
                </h2>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  <span>The assigned nurse will not have access to the booking once marked complete.</span>
                </p>
                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      width: "130px",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      borderRadius: "1.4rem",
                      //backgroundColor: "#e86956",
                    }}
                    color="secondary"
                    variant="outlined"
                    onClick={handleConfirmApplyModalClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      width: "130px",
                      backgroundColor: "#008690",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      borderRadius: "1.4rem",
                    }}
                    color="primary"
                    variant="contained"
                    onClick={handleReportingBtnAction}
                  >
                    Complete
                  </Button>
                </div>
              </div>
            </Modal>
            <Modal
              open={openModal}
              onClose={handleModalClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <ModalPopUp data={reportIndex} name={"report"} handleClose={handleModalClose} />
            </Modal>
            <Modal
              open={openActivityModal}
              onClose={handleActivityModalClose}
              sx={{
                display: "flex",
                justifyContent: "center",
                top: "5rem",
                "& .modal-card": {
                  width: "100%",
                  maxWidth: "700px",
                },
              }}
            >
              <div
                className="modal-card"
                style={{
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: "1rem",
                  padding: "2rem",
                  height: "max-content",
                  maxHeight: "70vh",
                  overflowY: "scroll",
                }}
              >
                <p style={{ float: "right" }}>
                  <MdCancel
                    style={{
                      position: "fixed",
                      marginRight: "2rem",
                      cursor: "pointer",
                    }}
                    color="#008690"
                    size="25"
                    onClick={handleActivityModalClose}
                  />
                </p>
                <h2
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Nurse Activity
                </h2>
                <div>{getShowActivity()}</div>
              </div>
            </Modal>
            {order?.orderUUID && (
              <NewAssignNurseModal
                open={openNurseAssignModal}
                order={order}
                setOrder={setorder}
                onAssignNurseSuccess={nurseChange}
                onClose={() => {
                  setopenNurseAssignModal(false);
                }}
              />
            )}
            {(order?.date?.length ?? 0) > 0 && (
              <AssignStaffModal
                key={order?.orderUUID}
                open={showSplitBookingModal}
                onAssignStaffSuccess={nurseChange}
                booking={order}
                onClose={function () {
                  setShowSplitBookingModal(false);
                }}
              />
            )}
            {order?.orderUUID && (
              <MarkVideoConsultationCompleteModal
                booking={order}
                setBooking={setorder}
                open={showMarkVideoConsultationCompleteModal}
                onClose={() => setShowMarkVideoConsultationCompleteModal(false)}
              />
            )}
            {order?.orderUUID && <RecommendationsRoutes setBooking={setorder} booking={order} />}
          </>
        )
      }
    />
  );
}

const labResultsConsultationProductUUUD = "0f5ecf63-275a-45a8-81c6-cadde3edbc0f";

export default ShowOrder;
