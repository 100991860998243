import { useQuery } from "@tanstack/react-query";
import { searchProducts } from "../services/schemas.services";
import { useMemo } from "react";

export default function useSearchProducts(params: { searchString?: string; location?: string; filter: "LAB" }) {
  const queryKey = useMemo(() => {
    return ["/api/vi/schemas/get-product-result-on-search-new", params.filter, params.location, params.searchString];
  }, [params.filter, params.location, params.searchString]);

  const query = useQuery(
    queryKey,
    () => searchProducts({ filter: params.filter, location: params.location ?? "", searchString: params.searchString ?? "" }),
    {
      enabled: !!params.filter && !!params.location && !!params.searchString,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return { query, queryKey };
}
