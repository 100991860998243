import {
  ConsultationCustomizationModel,
  ConsultationIvDripModel,
  ConsultationLabTestModel,
} from "../models/GetProductsForConsultationResponseModel";
import UploadFilePayloadModel from "../models/UploadFilePayloadModel";
import { BuildYourLabTestCustomizationItem } from "../services/schemas.services";
import { ConsultationRecommendationsType } from "../types";

export interface ConsultationRecommendationsInitialState {
  selectedLabTests?: ConsultationLabTestModel[] | "SKIP";
  selectedIVDrip?: ConsultationIvDripModel | "SKIP";
  selectedCustomizations?: ConsultationCustomizationModel[];
  selectedBuildYourLabTestItems?: BuildYourLabTestCustomizationItem[] | "SKIP";
  prescription?: UploadFilePayloadModel;
}

export const initialState: ConsultationRecommendationsInitialState = {};

export function consultationRecommendationsReducer(
  state = initialState,
  action: { type: keyof typeof ConsultationRecommendationsType; payload: Partial<ConsultationRecommendationsInitialState> }
): ConsultationRecommendationsInitialState {
  if (action.type === "RESET_CONSULTATION_RECOMMENDATIONS") {
    return {};
  } else if (action.type === "SET_CONSULTATION_RECOMMENDATIONS") {
    return Object.assign<object, ConsultationRecommendationsInitialState, ConsultationRecommendationsInitialState>(
      {},
      state,
      action.payload
    );
  }
  // else if (action.type === "SET_SUBMIT_RECOMMENDATIONS") {
  //   return Object.assign<object, ConsultationRecommendationsInitialState, ConsultationRecommendationsInitialState>({}, state, {
  //     submitRecommendations: action.payload,
  //   });
  // }
  return state;
}
