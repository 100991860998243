import { FC, useMemo } from "react";
import { ConsultationLabTestModel } from "../../models/GetProductsForConsultationResponseModel";
import { useParams } from "react-router-dom";
import { Box, Divider, ListItem, ListItemButton, Typography } from "@mui/material";
import RecommendationsCheckCircle from "./RecommendationsCheckCircle";
import useProductDetails from "../../hooks/useProductDetails";
import useOrderDetails from "../../hooks/useOrderDetails";

const SuggestLabTestsListItem: FC<SuggestLabTestsListItemProps> = ({ test, type, searchText, selected, onSelectClicked }) => {
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const { query: orderDetailsQuery } = useOrderDetails({ orderUUID: orderUUID });

  const { query: productDetailsQuery } = useProductDetails({
    productUUID: test.productUUID,
    area: orderDetailsQuery.data?.order?.emirate,
  });

  const trimSearchText = useMemo(() => searchText.toLocaleLowerCase().trim(), [searchText]);

  const isSearching = trimSearchText.length > 0;

  const testsIncluded = productDetailsQuery.data?.product?.testsIncluded ?? [];

  return (
    <ListItem key={test.productUUID} disablePadding>
      <ListItemButton
        disableRipple={true}
        onClick={() => {
          onSelectClicked(test);
        }}
        sx={{
          "&:hover": {
            backgroundColor: "#F7F7F7",
          },
          display: "flex",
          paddingY: "8px",
          flexDirection: "column",
          gap: 1,
          alignItems: "flex-start",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
          <Box display={"flex"} gap={1.5}>
            <img
              src={test.mainImageUrl}
              alt={test.name}
              style={{ width: "60px", height: "60px", borderRadius: "8px", objectFit: "cover" }}
            />
            <Box>
              <Typography width={"100%"}>{test.name}</Typography>
              {type === "BIOMARKER" ? <BiomarkerChip /> : <PackageChip />}
            </Box>
          </Box>
          <RecommendationsCheckCircle selected={selected} />
        </Box>
        {testsIncluded.length > 0 && (
          <Typography
            variant="subtitle2"
            sx={{
              letterSpacing: "-0.24px",
              "& .MuiButtonBase-root": {
                minWidth: "unset",
              },
            }}
          >
            <span>Contains:</span>{" "}
            <span style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: 400 }}>
              {testsIncluded.map((ing, i) => {
                const isLast = i === testsIncluded.length - 1;
                const isMatch = isSearching ? ing.toLocaleLowerCase().includes(trimSearchText) : false;
                return (
                  <span key={ing}>
                    <span style={{ color: isMatch ? "black" : undefined, backgroundColor: isMatch ? "yellow" : "unset" }}>
                      {ing}
                    </span>
                    {`${isLast ? "" : " - "}`}
                  </span>
                );
              })}
            </span>
          </Typography>
        )}
        <Divider sx={{ width: "100%" }} />
      </ListItemButton>
    </ListItem>
  );
};

const BiomarkerChip: FC = () => {
  return (
    <Box
      sx={{
        marginTop: "4px",
        backgroundColor: "#E8F1F7",
        borderRadius: "4px",
        paddingX: 1,
        paddingY: 0.1,
        color: "#1F6794",
        fontSize: "12px",
        width: "fit-content",
        letterSpacing: "-0.42px",
      }}
    >
      Biomarker
    </Box>
  );
};

const PackageChip: FC = () => {
  return (
    <Box
      sx={{
        marginTop: "4px",
        backgroundColor: "#FAE8E9",
        borderRadius: "4px",
        paddingX: 1,
        paddingY: 0.1,
        color: "#EA4136",
        fontSize: "12px",
        width: "fit-content",
        letterSpacing: "-0.42px",
      }}
    >
      Package
    </Box>
  );
};

interface SuggestLabTestsListItemProps {
  test: ConsultationLabTestModel;
  selected: boolean;
  searchText: string;
  type: "PACKAGE" | "BIOMARKER";
  onSelectClicked: (test: ConsultationLabTestModel) => void;
}

export default SuggestLabTestsListItem;
