import axios from "axios";
import decrypt from "./decrypt";
import encrypt from "./encrypt";
import Mixpanel from "../utils/Mixpanel";
import BaseResponseModel from "../models/BaseResponseModel";
import PartnerContactModel from "../models/PartnerContactModel";
import PartnerInventoryListItemModel from "../models/PartnerInventoryListItemModel";
import UploadFilePayloadModel from "../models/UploadFilePayloadModel";

export async function submitOutsourceRequest(params: {
  partnerUUID: string;
  invoiceTotalAmount: number;
  items: PartnerInventoryListItemModel[];
  invoiceData: UploadFilePayloadModel;
}) {
  try {
    const res = await axios.post(`/api/v1/inventory-requests/submit-outsource-request`, { data: encrypt(params) });
    const data: BaseResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- error submit-outsource-request", e);
    return null;
  }
}

export interface GetOutsourceInventoryItemsResponseModel extends BaseResponseModel {
  items?: string[];
}

export async function getOutsourceInventoryItems() {
  try {
    const res = await axios.get<string>("/api/v1/inventory-requests/get-outsource-inventory-items");
    const data: GetOutsourceInventoryItemsResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- getOutsourceInventoryItems error", e);
    return null;
  }
}

export interface GetDeliveryPartnerRevenueOrdersResponseModel extends BaseResponseModel {
  orders?: PartnerInventoryRequestModel[];
}

export async function getDeliveryPartnerRevenueOrders({ partnerUUID }: { partnerUUID: string }) {
  try {
    const res = await axios.get<string>(`/api/v1/inventory-requests/get-delivery-partner-revenue-orders/${partnerUUID}`);
    const data: GetDeliveryPartnerRevenueOrdersResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- getDeliveryPartnerRevenueOrders error", e);
    return null;
  }
}

export async function uploadDocForDeliveryOrder(params: {
  orderUUID: string;
  document: UploadFilePayloadModel | null;
  deliveryNoteInvoiceDate: Date;
  type: "DELIVERY_NOTE" | "INVOICE";
}) {
  try {
    const res = await axios.post<string>("/api/v1/inventory-requests/upload-docs-for-order", { data: encrypt(params) });
    const data: BaseResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- uploadDocForDeliveryOrder error", e);
    return null;
  }
}

export interface GetPartnerAddressResponseModel extends BaseResponseModel {
  address?: PartnerAddressModel;
}

export async function getPartnerAddress({ addressUUID }: { addressUUID: string }) {
  try {
    const res = await axios.get<string>(`/api/v1/inventory-requests/get-partner-address/${addressUUID}`);
    const data: GetPartnerAddressResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- getPartnerAddress error", e);
    return null;
  }
}

export interface GetPartnerContactResponseModel extends BaseResponseModel {
  contact?: PartnerContactModel;
}

export async function getPartnerContact({ contactUUID }: { contactUUID: string }) {
  try {
    const res = await axios.get<string>(`/api/v1/inventory-requests/get-partner-contact/${contactUUID}`);
    const data: GetPartnerContactResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- getPartnerContact error", e);
    return null;
  }
}

export async function getDeliveryPartnerOrders({ partnerUUID }: { partnerUUID: string }) {
  try {
    const res = await axios.get<string>(`/api/v1/inventory-requests/get-delivery-partner-requests/${partnerUUID}`);
    const data: GetAllPartnerRequestResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- getDeliveryPartnerOrders error", e);
    return null;
  }
}

export async function submitInventoryRequest(params: {
  contactUUID: string;
  addressUUID: string;
  partnerUUID: string;
  prescriptionData: UploadFilePayloadModel;
  inventoryList: PartnerInventoryListItemModel[];
}) {
  try {
    const res = await axios.post<string>("/api/v1/inventory-requests/submit-request", { data: encrypt(params) });
    const data: BaseResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- submit-request error", e);
    return null;
  }
}

export interface PartnerInventoryItemModel {
  name?: string;
  supplierPrice?: number;
}

export interface GetInventoryListResponseModel extends BaseResponseModel {
  list?: PartnerInventoryItemModel[];
}

export async function getInventoryList() {
  try {
    const res = await axios.get<string>("/api/v1/inventory-requests/get-inventory-list");
    const data: GetInventoryListResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- getInventoryList error", e);
    return null;
  }
}

export async function createPartnerAddress({ address }: { address: PartnerAddressModel }) {
  try {
    const res = await axios.post<string>(`/api/v1/inventory-requests/add-partner-address`, { data: encrypt(address) });
    const data: BaseResponseModel = decrypt(res.data);
    return data;
  } catch (e) {
    console.log("--- createPartnerAddress error", e);
    return null;
  }
}

export interface PartnerAddressModel {
  _id?: string;
  partnerUUID?: string;
  addressName?: string;
  buildingName?: string;
  floorNumber?: string;
  latitude?: string;
  longitude?: string;
  streetName?: string;
}

export interface GetAllPartnerAddressesResponseModel extends BaseResponseModel {
  addresses: PartnerAddressModel[];
}

export async function getAllPartnerAddresses({ partnerUUID }: { partnerUUID: string }) {
  try {
    const res = await axios.get<string>(`/api/v1/inventory-requests/get-all-partner-addresses/${partnerUUID}`);
    const data: GetAllPartnerAddressesResponseModel = decrypt(res.data);
    // console.log("--- get-all-partner-addresses", data);
    return data;
  } catch (e) {
    console.log("--- getAllPartnerAddresses error", e);
    Mixpanel.track("Partner get all addresses error", { partnerUUID });
    return null;
  }
}

export async function createPartnerContact({ contact }: { contact: PartnerContactModel }) {
  try {
    const res = await axios.post<string>("/api/v1/inventory-requests/add-partner-contact", { data: encrypt(contact) });
    const data: BaseResponseModel = decrypt(res.data);
    // Mixpanel.track("Partner created contact", { contact });
    // console.log("---/add-partner-contact", data);
    return data;
  } catch (e) {
    Mixpanel.track("Partner created contact error", { contact });
    return null;
  }
}

export interface GetAllPartnerContactsResponseModel extends BaseResponseModel {
  contacts?: PartnerContactModel[];
}

export async function getAllPartnerContacts({ partnerUUID }: { partnerUUID: string }) {
  try {
    const res = await axios.get<string>(`/api/v1/inventory-requests/get-all-partner-contacts/${partnerUUID}`);
    const data: GetAllPartnerContactsResponseModel = decrypt(res.data);
    // Mixpanel.track("Partner get all contacts", { partnerUUID });
    // console.log("--- get-all-partner-contacts", data);
    return data;
  } catch (e) {
    Mixpanel.track("Partner get all contacts error", { partnerUUID });
    return null;
  }
}

export type PartnerInventoryRequestStatus =
  | "PENDING_APPROVAL"
  | "ACCEPTED"
  | "REJECTED"
  | "DELIVERED"
  | "PENDING_DELIVERY_NOTE_APPROVAL"
  | "DELIVERY_NOTE_APPROVED"
  | "DELIVERY_NOTE_REJECTED";

export interface PartnerInventoryRequestModel {
  _id?: string;
  partnerUUID?: string;
  partnerAddressUUID?: string;
  inventoryRequirements?: PartnerInventoryListItemModel[];
  status?: PartnerInventoryRequestStatus;
  deliveryNoteUrl?: string;
  invoiceUrl?: string;
  partnerContactUUID?: string;
  orderUUID?: string;
  createdAt?: string;
  prescription?: string;
}

export interface GetAllPartnerRequestResponseModel extends BaseResponseModel {
  requests?: PartnerInventoryRequestModel[];
}

export async function getAllPartnerRequests({ partnerUUID }: { partnerUUID: string }) {
  try {
    const res = await axios.get<string>(`/api/v1/inventory-requests/get-all-partner-requests/${partnerUUID}`);
    const data: GetAllPartnerRequestResponseModel = decrypt(res.data);
    // Mixpanel.track("Partner get all inventory requests", { partnerUUID });
    return data;
  } catch (e) {
    Mixpanel.track("Partner get all inventory requests error", { partnerUUID });
    console.log("--- getAllPartnerRequests", e);
    return null;
  }
}
