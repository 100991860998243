import { buildYourLabTestProductUUID } from "../constants";
import BaseResponseModel from "../models/BaseResponseModel";
import ProductModel from "../models/ProductModel";
import axios from "../utils/axiosHelpers";
import decrypt from "./decrypt";
// import encrypt from "./encrypt";

export interface SearchProductsResponseModel extends BaseResponseModel {
  products?: ProductModel[];
}

export interface SearchBuildYourLabTestBiomarkersResponseModel extends BaseResponseModel {
  result?: BuildYourLabTestCustomizationItem[];
}

export async function searchBuildYourLabTestBiomarkers(params: { searchString: string }) {
  try {
    const res = await axios.get<string>(`/api/v1/schemas/search-for-build-your-lab-test-addon/${params.searchString}`);
    const data: SearchBuildYourLabTestBiomarkersResponseModel = decrypt(res.data);
    const seenItems = new Map<string, boolean>();
    data.result =
      data.result?.filter((item) => {
        if (seenItems.has(item.biomarkerName ?? "")) {
          return false;
        }
        seenItems.set(item.biomarkerName ?? "", true);
        return true;
      }) ?? [];
    return data;
  } catch (e) {
    console.log("--- searchBuildYourLabTestBiomarkers error", e);
    return null;
  }
}

export async function searchProducts(params: { searchString: string; location: string; filter: "LAB" }) {
  try {
    const res = await axios.get<string>(
      `/api/v1/schemas/get-product-result-on-search-new/${params.searchString}/${params.location}/${params.filter}`
    );
    const data: SearchProductsResponseModel = decrypt(res.data);
    // console.log("search", data);
    data.products = data.products?.filter((prod) => prod.productUUID !== buildYourLabTestProductUUID);
    return data;
  } catch (e) {
    console.log("--- error ", e);
    return null;
  }
}

export async function getBiomarkersMasterList() {
  const res = await axios.get<string>("/api/v1/schemas/get-addons-new");
  const data: GetBuildYourLabTestCustomizationsResponseModel = decrypt(res.data);
  return data;
}

interface GetBuildYourLabTestCustomizationsResponseModel extends BaseResponseModel {
  addOns?: BuildYourLabTestCustomizationItem[];
}

export interface BuildYourLabTestCustomizationItem {
  "App Price"?: number;
  "TAT Days"?: number;
  "Test Name"?: string;
  FASTING?: boolean;
  arbName?: string;
  engName?: string;
  engDescription?: string;
  arbDescription?: string;
  price?: number;
  biomarkerName?: string;
  isFastingRequired?: boolean;
  shouldFast?: boolean;
  sample?: string;
  SAMPLE?: string;
  customizationUUID?: string;
  sampleType?: string;
  _id?: string;
}

export async function getBuildYourLabTestAddons(): Promise<GetBuildYourLabTestCustomizationsResponseModel | null> {
  try {
    const res = await axios.get<string>(`/api/v1/schemas/getaddons/${buildYourLabTestProductUUID}`);
    const decryptedData: GetBuildYourLabTestCustomizationsResponseModel = decrypt(res.data);
    return decryptedData;
  } catch (error) {
    console.log("--- getBuildYourLabTestAddons error", error);
    return null;
  }
}

export interface GetProductDetailsResponseModel extends BaseResponseModel {
  product?: ProductModel;
}

export async function getProductDetails(params: {
  productUUID: string;
  area: string;
}): Promise<GetProductDetailsResponseModel | null> {
  try {
    const res = await axios.get<string>(`/api/v1/schemas/getparticularproductwithlocation/${params.productUUID}/${params.area}`);
    const data: GetProductDetailsResponseModel = decrypt(res.data);
    return data;
  } catch (error) {
    console.log("--- getProductDetails error", error);
    return null;
  }
}

export async function getAllProducts() {
  try {
    // const res = await axios.get('')
  } catch (e) {
    console.log("--- getAllProducts error", e);
    return null;
  }
}
