import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { showMessage } from "../components/common/notification";
import BookingModel, {
  RecommendationsRequestProductsModel,
  RecommendationsRequestProductItemModel,
} from "../models/BookingModel";
import { handleMultiProductAddon } from "../services/connected.care.service";
import { ConsultationRecommendationsType } from "../types";
import useOrderDetails from "./useOrderDetails";
import ReduxStateModel from "../models/ReduxStateModel";
import usePartnerOrders from "./usePartnerOrders";
import useSearchOrderData from "./useSearchOrderData";
import useSortOrdersQuery from "./useSortOrdersQuery";

export default function useConfirmRecommendations({
  setBooking,
}: {
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
}) {
  const dispatch = useDispatch();
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);

  const { query, queryKey: orderDetailsQueryKey } = useOrderDetails({ cacheTime: Infinity, orderUUID: orderUUID });

  const handleClose = useCallback(() => {
    history.replace(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  const ivDrip = consultationRecommendationsReducer.selectedIVDrip;
  const ivDripAddons = consultationRecommendationsReducer.selectedCustomizations;
  const labTests = consultationRecommendationsReducer.selectedLabTests;
  const buildYourLabTestAddons = consultationRecommendationsReducer.selectedBuildYourLabTestItems;

  const saveMutation = useMutation({ mutationFn: handleMultiProductAddon });

  const status = query.data?.order?.status;

  const { queryKey: partnerOrdersQueryKey } = usePartnerOrders({
    status: query.data?.order?.status,
    loadMoreElementInView: false,
    enabled: true,
  });

  const { queryKey: searchOrdersQueryKey } = useSearchOrderData("BOOKING_ID", orderUUID, 1);

  const { queryKey: sortQueryOrdersKey } = useSortOrdersQuery({
    pageNumber: 1,
    type: "ASCENDING",
    value: { endDate: "", startDate: "" },
    sortUnreportedOrdersOnly: true,
    filter: {
      nurse: query.data?.order?.assignedNurses?.map((nurse) => nurse.nurseUUID ?? ""),
      service: query.data?.order?.patientId?.map((patient) => patient.productUUID ?? ""),
    },
    view: status === "ACCEPTED" ? "ACCEPT" : status === "PROCESSING" ? "ASSIGN" : "REPORT",
  });

  const handleConfirmClicked = useCallback(async () => {
    const reqProducts: RecommendationsRequestProductsModel[] = [];
    if (ivDrip && ivDrip !== "SKIP") {
      reqProducts.push({
        type: "DRIPS",
        customizations: ivDripAddons ?? [],
        multipleProducts: [
          { imageUrl: ivDrip.mainImageUrl ?? "", name: ivDrip.name ?? "", productUUID: ivDrip.productUUID ?? "" },
        ],
        productUUIDs: [ivDrip.productUUID ?? ""],
      });
    }
    if (labTests && labTests !== "SKIP") {
      reqProducts.push({
        type: "LAB_TESTS",
        customizations: buildYourLabTestAddons && buildYourLabTestAddons !== "SKIP" ? buildYourLabTestAddons : [],
        multipleProducts: labTests.map<RecommendationsRequestProductItemModel>((test) => {
          return {
            imageUrl: test.mainImageUrl ?? "",
            name: test.name ?? "",
            productUUID: test.productUUID ?? "",
          };
        }),
        productUUIDs: labTests.map((test) => test.productUUID ?? ""),
      });
    }
    if (consultationRecommendationsReducer.prescription) {
      reqProducts.push({
        type: "PRESCRIPTION",
        data: consultationRecommendationsReducer.prescription,
      });
    }
    const res = await saveMutation.mutateAsync({
      orderUUID: orderUUID,
      blockUUID: query.data?.order?.blockUUID ?? "",
      programBookingId: query.data?.order?.programBookingId ?? "",
      products: reqProducts,
    });
    if (!res.success) {
      return showMessage("error", "Something went wrong");
    }
    history.replace(`/order/${orderUUID}`);
    setBooking((value) => {
      const newValue = { ...value };
      newValue.recommendations = reqProducts;
      return newValue;
    });
    queryClient.invalidateQueries(orderDetailsQueryKey);
    queryClient.invalidateQueries(partnerOrdersQueryKey);
    queryClient.invalidateQueries(searchOrdersQueryKey);
    queryClient.invalidateQueries(sortQueryOrdersKey);
    dispatch({ type: ConsultationRecommendationsType.RESET_CONSULTATION_RECOMMENDATIONS });
    showMessage("success", "Recommendations were saved successfully");
  }, [
    dispatch,
    setBooking,
    buildYourLabTestAddons,
    history,
    ivDrip,
    ivDripAddons,
    labTests,
    orderDetailsQueryKey,
    orderUUID,
    partnerOrdersQueryKey,
    queryClient,
    saveMutation,
    searchOrdersQueryKey,
    sortQueryOrdersKey,
    query.data?.order?.blockUUID,
    query.data?.order?.programBookingId,
    consultationRecommendationsReducer.prescription,
  ]);

  const showLabTests = !!labTests && labTests !== "SKIP";
  const showLabTestAddon = !!buildYourLabTestAddons && buildYourLabTestAddons !== "SKIP";

  return {
    handleConfirmClicked,
    handleClose,
    saveMutation,
    ivDrip,
    buildYourLabTestAddons,
    ivDripAddons,
    showLabTests,
    labTests,
    showLabTestAddon,
  };
}
