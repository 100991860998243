import { createTheme } from "@mui/material";

const pharmacyTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#25bcbd",
      contrastText: "rgba(255,255,255,0.87)",
      dark: "#008690",
    },
    // secondary: {
    //   main: "#0d47a1",
    // },
  },
  typography: {
    fontFamily: `"Inter", "sans-serif"`,
    h5: {
      fontSize: "16px",
      fontWeight: "500",
    },
    h4: {
      fontSize: "20px",
      fontWeight: "500",
    },
    h3: {
      fontSize: "24px",
      fontWeight: "500",
    },
    body1: {
      color: "#1e1f21",
    },
    body2: {
      color: "#1e1f21",
    },
    caption: { fontWeight: "500", fontSize: "12px", color: "#6d6e6f" },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: { borderRadius: "4px", backgroundColor: "#1e1f21" },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-sizeSmall": {
            height: "32px",
            fontSize: "14px",
            "& .MuiInputAdornment-root .MuiTypography-root": {
              fontSize: "14px",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          padding: "4px 8px",
          fontSize: "12px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          borderRadius: "6px",
          textTransform: "capitalize",
          "&.Mui-disabled": {
            opacity: 0.5,
            cursor: "not-allowed",
          },
          // "& .MuiButton-startIcon svg": {
          //   width: "12px",
          //   height: "12px",
          // },
        },
        text: {
          padding: "4px 8px",
          fontSize: "12px",
          color: "#6d6e6f",
          fill: "#6d6e6f",
          borderRadius: "6px",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "rgba(55, 23, 23, 0.03)",
            color: "#1e1f21",
            fill: "#1e1f21",
          },
          "&.Mui-disabled": {
            opacity: 0.5,
            cursor: "not-allowed",
          },
          // "& .MuiButton-startIcon svg": {
          //   width: "12px",
          //   height: "12px",
          // },
        },
        outlined: {
          fontSize: "12px",
          // backgroundColor: "#4573d2",
          color: "#1e1f21",
          borderRadius: "6px",
          padding: "4px 8px",
          textTransform: "none",
          borderColor: "#cfcbcb",
          "&.staff-scheduler-selector": {
            width: "30px",
            height: "30px",
            minWidth: "30px",
            minHeight: "30px",
            borderRadius: "50%",
          },
          "&.success": {
            backgroundColor: "#e6f8f1",
            borderColor: "#8dc2ac",
            color: "#0d7f56",
            fill: "#0d7f56",
          },
          "&.primary": {
            backgroundColor: "white",
            borderColor: "#25bcbd",
            color: "#25bcbd",
            fill: "#25bcbd",
          },
          "&.primary:hover": {
            borderColor: "#25bcbd",
          },
          "&.success-hover:hover": {
            backgroundColor: "#e0f4ec",
            borderColor: "#58a182",
            color: "#12714d",
            fill: "#12714d",
          },
          "&.Mui-disabled": {
            opacity: 0.5,
            cursor: "not-allowed",
          },
          "&:hover": {
            backgroundColor: "#f9f8f8",
            borderColor: "#afabac",
          },
          "& .MuiButton-startIcon": {
            marginRight: "6px",
          },
          // "& .MuiButton-startIcon svg": {
          //   width: "12px",
          //   height: "12px",
          // },
        },
        sizeSmall: {
          height: "28px",
          padding: "2px 8px",
          borderRadius: "6px",
        },
      },
    },
    MuiToolbar: {
      defaultProps: {
        style: { height: "50px" },
      },
    },
    MuiDivider: {
      defaultProps: {
        style: { borderColor: "#edeae9" },
      },
    },
    MuiAppBar: {
      defaultProps: {
        style: { height: "50px" },
      },
    },
  },
  // overrides: {
  //   MuiAppBar: {
  //     colorInherit: {
  //       backgroundColor: "red",
  //       color: "#fff",
  //     },
  //   },
  // },
  // props: {
  //   MuiAppBar: {
  //     color: "inherit",
  //   },
  // },
});

export default pharmacyTheme;
