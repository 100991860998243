import { Box, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import ReduxStateModel from "../../models/ReduxStateModel";
import SuggestionsUploadPrescriptionButton from "./SuggestionsUploadPrescriptionButton";
import SuggestionsLabTestButton from "./SuggestionsLabTestButton";
import SuggestionsIVDripsButton from "./SuggestionsIVDripsButton";
import SuggestionsModalDialog from "./SuggestionsModalDialog";
import useConfirmRecommendations from "../../hooks/useConfirmRecommendations";
import BookingModel from "../../models/BookingModel";

const SuggestionsModal: FC<SuggestionsModalProps> = ({ setBooking }) => {
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const history = useHistory();
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);

  const handleClose = useCallback(() => {
    history.push(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  const didSelectIVDrip = useMemo(() => {
    return !!consultationRecommendationsReducer.selectedIVDrip && consultationRecommendationsReducer.selectedIVDrip !== "SKIP";
  }, [consultationRecommendationsReducer.selectedIVDrip]);

  const didSelectLabTest = useMemo(() => {
    if (
      consultationRecommendationsReducer.selectedBuildYourLabTestItems !== "SKIP" &&
      consultationRecommendationsReducer.selectedBuildYourLabTestItems?.length
    ) {
      return true;
    }
    return (
      !!consultationRecommendationsReducer.selectedLabTests?.length &&
      consultationRecommendationsReducer.selectedLabTests !== "SKIP"
    );
  }, [consultationRecommendationsReducer.selectedBuildYourLabTestItems, consultationRecommendationsReducer.selectedLabTests]);

  const didSelectPrescription = useMemo(() => {
    return !!consultationRecommendationsReducer.prescription;
  }, [consultationRecommendationsReducer.prescription]);

  const numberOfCompletedSteps = useMemo(() => {
    if (didSelectIVDrip && didSelectLabTest && didSelectPrescription) {
      return 3;
    }
    return [didSelectIVDrip, didSelectLabTest, didSelectPrescription].filter(Boolean).length;
  }, [didSelectIVDrip, didSelectLabTest, didSelectPrescription]);

  const ivDripsButton = useMemo(() => {
    return <SuggestionsIVDripsButton />;
  }, []);

  const prescriptionButton = useMemo(() => {
    return <SuggestionsUploadPrescriptionButton />;
  }, []);

  const labTestsButton = useMemo(() => {
    return <SuggestionsLabTestButton />;
  }, []);

  const { handleConfirmClicked, saveMutation } = useConfirmRecommendations({ setBooking });

  return (
    <SuggestionsModalDialog open={true} onClose={handleClose}>
      <Typography display={"flex"} alignItems={"flex-end"} gap={1} marginBottom={2}>
        <Box
          fontWeight={600}
          letterSpacing={"-0.28px"}
          fontSize={"56px"}
          lineHeight={"60px"}
          color={"#1D1D1F"}
          sx={{
            "@media (max-width: 700px)": {
              fontSize: "30px",
            },
          }}
        >
          Take Action
        </Box>
        <Box
          fontSize={"14px"}
          fontWeight={600}
          color={"rgba(29, 29, 31, 0.40)"}
          paddingBottom={1}
          sx={{
            "@media (max-width: 700px)": {
              paddingBottom: "16px",
            },
          }}
        >
          ({numberOfCompletedSteps} / 3)
        </Box>
      </Typography>
      <Typography
        color={"#59595B"}
        fontWeight={600}
        width={"100%"}
        maxWidth={"500px"}
        letterSpacing={"0.228px"}
        lineHeight={"120%"}
        marginBottom={4}
      >
        Quickly manage patient care with simple steps – Upload Prescription, Suggest Tests, and Recommend IV Drips.
      </Typography>
      {!didSelectIVDrip && ivDripsButton}
      {!didSelectLabTest && labTestsButton}
      {!didSelectPrescription && prescriptionButton}
      {numberOfCompletedSteps > 0 && (
        <Typography
          fontWeight={600}
          width={"100%"}
          maxWidth={"500px"}
          letterSpacing={"0.228px"}
          lineHeight={"120%"}
          marginBottom={2}
          marginTop={4}
        >
          Added
        </Typography>
      )}
      {didSelectIVDrip && ivDripsButton}
      {didSelectLabTest && labTestsButton}
      {didSelectPrescription && prescriptionButton}
      {numberOfCompletedSteps > 0 && (
        <LoadingButton
          variant="contained"
          onClick={handleConfirmClicked}
          loading={saveMutation.isLoading}
          disableElevation={true}
          size="large"
          sx={{
            borderRadius: "40px",
            marginTop: 4,
            "&.MuiButtonBase-root": {
              color: "white",
              backgroundColor: "black",
            },
          }}
        >
          Confirm
        </LoadingButton>
      )}
    </SuggestionsModalDialog>
  );
};

interface SuggestionsModalProps {
  setBooking: React.Dispatch<React.SetStateAction<BookingModel>>;
}

export default SuggestionsModal;
