import { Box } from "@mui/material";
import { FC } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const RecommendationsCheckCircle: FC<RecommendationsCheckCircleProps> = ({ size, selected, iconSize }) => {
  return (
    <Box
      width={size ?? "20px"}
      height={size ?? "20px"}
      minWidth={size ?? "20px"}
      minHeight={size ?? "20px"}
      borderRadius={"50%"}
      border={`1px solid #34C759`}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ backgroundColor: selected ? "#34C759" : "unset", transition: "background-color 0.2s ease-out" }}
    >
      <CheckRoundedIcon sx={{ color: "#F7F7F7", fontSize: iconSize ?? "16px" }} />
    </Box>
  );
};

interface RecommendationsCheckCircleProps {
  selected: boolean;
  size?: string;
  iconSize?: string;
}

export default RecommendationsCheckCircle;
