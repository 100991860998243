import { LoadingButton } from "@mui/lab";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useCallback } from "react";
import { GetPharmacyOrdersResponseModel, markOrderAsDispatched } from "../../services/pharmacy.orders.service";
import { showMessage } from "../common/notification";
import PharmacyBookingModel from "../../models/PharmacyBookingModel";
import usePharmacyOrders from "../../hooks/usePharmacyOrders";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";

const MarkOrderDispatchedButton: FC<MarkOrderDispatchedButtonProps> = ({ order }) => {
  const history = useHistory();
  const markDispatchedMutation = useMutation({ mutationFn: markOrderAsDispatched });
  const { queryKey: newOrdersQueryKey } = usePharmacyOrders({ status: "PRESCRIPTION_UPLOADED" });
  const { queryKey: dispatchedOrdersQueryKey } = usePharmacyOrders({ status: "DISPATCHED" });
  //   const { query: deliveredOrdersQuery } = usePharmacyOrders({ status: "DELIVERED" });
  const queryClient = useQueryClient();

  const handleClick = useCallback(async () => {
    const res = await markDispatchedMutation.mutateAsync({ orderUUID: order.orderUUID ?? "" });
    if (res?.success) {
      queryClient.setQueryData(newOrdersQueryKey, (data: unknown) => {
        if (!data) return data;
        const newValue: GetPharmacyOrdersResponseModel = JSON.parse(JSON.stringify(data));
        newValue.pharmacyOrders = newValue.pharmacyOrders?.filter((o) => o.orderUUID !== order.orderUUID);
        return newValue;
      });
      queryClient.setQueryData(dispatchedOrdersQueryKey, (data: unknown) => {
        if (!data) return data;
        const newValue: GetPharmacyOrdersResponseModel = JSON.parse(JSON.stringify(data));
        const updatedOrder: PharmacyBookingModel = { ...order };
        updatedOrder.appStatus = "DISPATCHED";
        newValue.pharmacyOrders?.unshift(updatedOrder);
        return newValue;
      });
      queryClient.invalidateQueries(newOrdersQueryKey);
      queryClient.invalidateQueries(dispatchedOrdersQueryKey);
      showMessage("success", "Order was marked as dispatched successfully");
      history.replace("/pharmacy");
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [dispatchedOrdersQueryKey, history, markDispatchedMutation, newOrdersQueryKey, order, queryClient]);

  return (
    <Tooltip title={"Will be enabled once customer pays"}>
      <div>
        <LoadingButton
          disabled={!order.itemsPaidFor}
          onClick={handleClick}
          loading={markDispatchedMutation.isLoading}
          className="primary"
          variant="outlined"
        >
          Mark Dispatched
        </LoadingButton>
      </div>
    </Tooltip>
  );
};

interface MarkOrderDispatchedButtonProps {
  order: PharmacyBookingModel;
}

export default MarkOrderDispatchedButton;
