import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import PharmacyBookingModel, { PharmacyOrderItem } from "../../models/PharmacyBookingModel";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GetPharmacyOrdersResponseModel, updatePharmacyOrderAvaialbleItems } from "../../services/pharmacy.orders.service";
import { showMessage } from "../common/notification";
import usePharmacyOrders from "../../hooks/usePharmacyOrders";
import usePharmacyOrder from "../../hooks/usePharmacyOrder";
import AvailableItemsList from "./AvailableItemsList";
import UnavailableItemsList from "./UnavailableItemsList";

const AddAvailableItemsButton: FC<AddAvailableItemsButtonProps> = ({ order }) => {
  const items = useMemo(() => order.items ?? [], [order.items]);
  const availableItems = order.availableItems;
  const orderUUID = order.orderUUID;
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<PharmacyOrderItem[]>([...(availableItems ?? items)]);

  const { queryKey: newOrdersQueryKey } = usePharmacyOrders({ status: "PRESCRIPTION_UPLOADED" });
  const { queryKey: orderDetailsQueryKey } = usePharmacyOrder({ orderUUID: orderUUID });

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const addAvailableItemsMutation = useMutation({ mutationFn: updatePharmacyOrderAvaialbleItems });

  const handleSaveAvailableItems = useCallback(async () => {
    const res = await addAvailableItemsMutation.mutateAsync({ items: selectedItems, orderUUID: orderUUID ?? "" });
    if (res?.success) {
      showMessage("success", "Items ware added successfully");
      setOpen(false);
      queryClient.setQueryData(newOrdersQueryKey, (data: unknown) => {
        if (!data) return data;
        const newValue: GetPharmacyOrdersResponseModel = JSON.parse(JSON.stringify(data));
        for (const order of newValue.pharmacyOrders ?? []) {
          if (order.orderUUID === orderUUID) {
            order.availableItems = selectedItems;
          }
        }
        return newValue;
      });
      queryClient.resetQueries(orderDetailsQueryKey);
      queryClient.invalidateQueries(newOrdersQueryKey);
    } else {
      showMessage("error", "Something went wrong");
    }
  }, [addAvailableItemsMutation, newOrdersQueryKey, orderDetailsQueryKey, orderUUID, queryClient, selectedItems]);

  // const value = useMemo<{ label: string; id: string }[]>(() => {
  //   return selectedItems.map((item) => {
  //     return {
  //       id: item.productUUID ?? "",
  //       label: `${item.productName} - ${item.dosage}`,
  //     };
  //   });
  // }, [selectedItems]);

  const handleRemoveAvailableItem = useCallback((item: PharmacyOrderItem) => {
    setSelectedItems((items) => {
      return items.filter((i) => i.productUUID !== item.productUUID);
    });
  }, []);

  const handleAddAvailableItem = useCallback((item: PharmacyOrderItem) => {
    setSelectedItems((value) => [...value, item]);
  }, []);

  const handleItemPriceChanged = useCallback((item: PharmacyOrderItem, price: number) => {
    setSelectedItems((value) => {
      return value.map<PharmacyOrderItem>((i) => {
        if (i.productUUID === item.productUUID) {
          return { ...i, price: price };
        }
        return i;
      });
    });
  }, []);

  const disableSaveButton = useMemo(() => {
    if (selectedItems.length === 0) return true;
    if (selectedItems.some((item) => !item.price)) return true;
    return false;
  }, [selectedItems]);

  const unavailableItems = useMemo(() => {
    const selectedIDs = selectedItems.map((item) => item.productUUID);
    return items.filter((item) => {
      return !selectedIDs.includes(item.productUUID);
    });
  }, [items, selectedItems]);

  return (
    <>
      <LoadingButton onClick={handleClick} className="primary" variant="outlined">
        {(order.availableItems?.length ?? 0) > 0 ? "Edit Available Items" : "Add Available Items"}
      </LoadingButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="add-available-items-dialog"
        aria-describedby="add-available-items-dialog-description"
        maxWidth="md"
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
          },
        }}
      >
        <DialogTitle id="add-available-items-dialog" sx={{ fontSize: "16px" }}>
          {"Select and enter the price for the available items"}
        </DialogTitle>
        <DialogContent>
          <Divider textAlign="left">Available Items</Divider>
          <AvailableItemsList
            selectedItems={selectedItems}
            handleRemoveAvailableItem={handleRemoveAvailableItem}
            onPriceChange={handleItemPriceChanged}
          />
          <Divider textAlign="left">Unavailable Items</Divider>
          <UnavailableItemsList items={unavailableItems} handleAddAvailableItem={handleAddAvailableItem} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            disabled={disableSaveButton}
            variant="outlined"
            className="success success-hover"
            onClick={handleSaveAvailableItems}
            loading={addAvailableItemsMutation.isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface AddAvailableItemsButtonProps {
  order: PharmacyBookingModel;
}

export default AddAvailableItemsButton;
