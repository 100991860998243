import { Box, Button, Divider, ListItem, ListItemButton, Typography } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { ConsultationIvDripModel } from "../../models/GetProductsForConsultationResponseModel";
import RecommendationsCheckCircle from "./RecommendationsCheckCircle";

const limit = 160;

const SuggestIVDripModalListItem: FC<SuggestIVDripModalListItemProps> = ({ onSelectClicked, selected, ivDrip }) => {
  const [showAllText, setShowAllText] = useState(false);

  const ingredientsStr = useMemo(() => {
    const strValue =
      ivDrip.ingredients
        ?.split("\n")
        .filter((ing) => !!ing.trim().length)
        .join(", ") ?? "";
    return strValue;
  }, [ivDrip.ingredients]);

  const ingredients = useMemo(() => {
    if (ingredientsStr?.length > limit && !showAllText) {
      return ingredientsStr.slice(0, limit);
    }
    return ingredientsStr;
  }, [ingredientsStr, showAllText]);

  return (
    <ListItem key={ivDrip.productUUID} disablePadding>
      <ListItemButton
        disableRipple={true}
        onClick={() => {
          onSelectClicked(ivDrip);
        }}
        sx={{
          "&:hover": {
            backgroundColor: "#F7F7F7",
          },
          display: "flex",
          paddingY: "8px",
          flexDirection: "column",
          gap: 1,
          alignItems: "flex-start",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
          <Box display={"flex"} gap={1.5}>
            <img
              src={ivDrip.mainImageUrl}
              alt={ivDrip.name}
              style={{ width: "60px", height: "60px", borderRadius: "8px", objectFit: "cover" }}
            />
            <Typography width={"100%"} maxWidth={"300px"}>
              {ivDrip.name}
            </Typography>
          </Box>
          <RecommendationsCheckCircle selected={selected} />
        </Box>
        <Typography
          variant="subtitle2"
          sx={{
            "& .MuiButtonBase-root": {
              minWidth: "unset",
            },
          }}
        >
          <span>Ingredients:</span> <span style={{ color: "rgba(0, 0, 0, 0.50)" }}>{ingredients}</span>
          {ingredientsStr.length > limit && (
            <Button
              size="small"
              sx={{
                display: "inline",
                paddingY: "0px",
                backgroundColor: "#F7F7F7",
                boxShadow: showAllText ? undefined : "-15px 0px 10px 0px #F7F7F7",
                "&:hover": {
                  backgroundColor: "#F7F7F7",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowAllText((prev) => !prev);
              }}
            >
              {showAllText ? "less" : `more`}
            </Button>
          )}
        </Typography>
        <Divider sx={{ width: "100%" }} />
      </ListItemButton>
    </ListItem>
  );
};

interface SuggestIVDripModalListItemProps {
  ivDrip: ConsultationIvDripModel;
  selected: boolean;
  onSelectClicked: (drip: ConsultationIvDripModel) => void;
}

export default SuggestIVDripModalListItem;
