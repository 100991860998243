import { Box, Button, InputAdornment, List, Typography } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ConsultationIvDripModel } from "../../models/GetProductsForConsultationResponseModel";
import { ConsultationRecommendationsType } from "../../types/consultation.recommendations.type";
import { useDispatch, useSelector } from "react-redux";
import { ConsultationRecommendationsInitialState } from "../../reducer/consultation.recommendations.reducer";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import TextField from "@mui/material/TextField";
import SuggestIVDripModalListItem from "./SuggestIVDripModalListItem";
import useProductsForConsultationQuery from "../../hooks/useProductsForConsultationQuery";
import LoadingSpinner from "../../components/LoadingSpinner";
import ReduxStateModel from "../../models/ReduxStateModel";
import Mixpanel from "../../utils/Mixpanel";
import SuggestionsModalDialog from "./SuggestionsModalDialog";

const SuggestIVDripsModal: FC<SuggestIVDripsModalProps> = ({ loading }) => {
  const dispatch = useDispatch();
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);
  const history = useHistory();
  const { orderUUID } = useParams<{ orderUUID: string }>();
  const [searchText, setSearchText] = useState("");
  const [selectedDrip, setSelectedDrip] = useState<ConsultationIvDripModel | undefined>(() => {
    if (consultationRecommendationsReducer.selectedIVDrip && consultationRecommendationsReducer.selectedIVDrip !== "SKIP") {
      return consultationRecommendationsReducer.selectedIVDrip;
    }
  });

  const handleClose = useCallback(() => {
    history.push(`/order/${orderUUID}`);
  }, [history, orderUUID]);

  const { query } = useProductsForConsultationQuery();

  const dripsList = useMemo(() => {
    if (!searchText.trim().length) return query.data?.ivDrips;
    return query.data?.ivDrips?.filter((drip) => {
      return drip.name?.toLocaleLowerCase().includes(searchText.trim().toLocaleLowerCase());
    });
  }, [query.data?.ivDrips, searchText]);

  const handleContinueClicked = useCallback(() => {
    const payload: Partial<ConsultationRecommendationsInitialState> = {
      selectedIVDrip: selectedDrip,
    };
    dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    Mixpanel.track("SuggestIVDripsModal continue clicked", {
      ...payload,
      partnerUser: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    if (selectedDrip?.customizations?.length) {
      history.push(`/order/${orderUUID}/suggestions/iv-drips/customizations`);
    } else {
      history.push(`/order/${orderUUID}/suggestions`);
    }
    // else if (consultationRecommendationsReducer.selectedLabTests === undefined) {
    //   history.push(`/order/${orderUUID}/suggestions/lab-tests`);
    // } else {
    //   history.push(`/order/${orderUUID}/suggestions/confirm`);
    // }
  }, [dispatch, history, orderUUID, selectedDrip]);

  const handleSkipClicked = useCallback(() => {
    const payload: Partial<ConsultationRecommendationsInitialState> = {
      selectedIVDrip: "SKIP",
    };
    Mixpanel.track("SuggestIVDripsModal skip clicked", {
      ...payload,
      partnerUser: localStorage.getItem("userName"),
      partnerUserUUID: localStorage.getItem("partnerUserUUID"),
    });
    dispatch({ type: ConsultationRecommendationsType.SET_CONSULTATION_RECOMMENDATIONS, payload: payload });
    if (consultationRecommendationsReducer.selectedLabTests === undefined) {
      history.push(`/order/${orderUUID}/suggestions`);
    } else if (consultationRecommendationsReducer.selectedBuildYourLabTestItems?.length) {
      history.push(`/order/${orderUUID}/suggestions`);
    } else if (consultationRecommendationsReducer.selectedLabTests === "SKIP") {
      history.push(`/order/${orderUUID}`);
    } else {
      history.push(`/order/${orderUUID}/suggestions`);
    }
  }, [
    consultationRecommendationsReducer.selectedBuildYourLabTestItems?.length,
    consultationRecommendationsReducer.selectedLabTests,
    history,
    orderUUID,
    dispatch,
  ]);

  return (
    <SuggestionsModalDialog open={true} onClose={handleClose}>
      <Typography display={"flex"} alignItems={"flex-end"} gap={1} marginBottom={2}>
        <Box
          fontWeight={600}
          letterSpacing={"-0.28px"}
          fontSize={"56px"}
          lineHeight={"60px"}
          color={"#1D1D1F"}
          sx={{
            "@media (max-width: 700px)": {
              fontSize: "30px",
            },
          }}
        >
          IV Drips
        </Box>
      </Typography>
      <Box
        marginBottom={2}
        sx={{
          "& .MuiInputBase-root": {
            border: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <TextField
          placeholder="Search"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.36)" }} />
              </InputAdornment>
            ),
          }}
          fullWidth={true}
          sx={{ backgroundColor: "#F7F7F7", border: "none", borderRadius: "8px" }}
          autoFocus={true}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </Box>
      <LoadingSpinner spinning={query.isLoading || loading} styles={{ minHeight: "100px" }}>
        <List
          dense
          sx={{
            width: "100%",
            bgcolor: "#F7F7F7",
            borderRadius: "14px",
            height: "100%",
            minHeight: "48vh",
            maxHeight: "48vh",
            overflow: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              backgroundColor: "unset",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#1D1D1F",
              borderRadius: "10px",
            },
          }}
        >
          {dripsList?.map((ivDrip) => {
            return (
              <SuggestIVDripModalListItem
                key={ivDrip.productUUID}
                ivDrip={ivDrip}
                selected={ivDrip.productUUID === selectedDrip?.productUUID}
                onSelectClicked={function (drip: ConsultationIvDripModel): void {
                  if (selectedDrip?.productUUID === drip.productUUID) {
                    setSelectedDrip(undefined);
                  } else {
                    setSelectedDrip(drip);
                  }
                }}
              />
            );
          })}
        </List>
      </LoadingSpinner>
      <Button
        variant={"contained"}
        size="large"
        sx={{
          borderRadius: "30px",
          backgroundColor: "black",
          marginTop: 4,
          color: "white",
          "&.MuiButtonBase-root.MuiButton-root": {
            color: "white",
            fontWeight: 400,
          },
          "&:hover": {
            backgroundColor: "black",
          },
        }}
        fullWidth={true}
        disabled={query.isLoading || loading}
        disableElevation={true}
        onClick={selectedDrip ? handleContinueClicked : handleSkipClicked}
      >
        {selectedDrip ? "Continue" : "Go Back"}
      </Button>
    </SuggestionsModalDialog>
  );
};

interface SuggestIVDripsModalProps {
  loading: boolean;
}

export default SuggestIVDripsModal;
