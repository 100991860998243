import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogTitle, Tooltip } from "@mui/material";
import { Call } from "@stream-io/video-react-sdk";
import { FC, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { updateCallStatus } from "../../services";
import { trtVideoConsultationProductUUID } from "../../constants";
import useOrderDetails from "../../hooks/useOrderDetails";

const EndCallButton: FC<EndCallButtonProps> = ({ call, orderUUID, callLoading, onLeaveCall }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [endCallLoading, setEndCallLoading] = useState(false);

  const { query } = useOrderDetails({ orderUUID: orderUUID });
  const productUUID = query.data?.order?.patientId?.[0]?.productUUID;

  const handleEndCallClicked = useCallback(async () => {
    setEndCallLoading(true);
    if (call) {
      try {
        await Promise.all([call.leave(), call.endCall()]);
      } catch (e) {
        console.log();
      }
    }
    updateCallStatus({ orderUUID: orderUUID, type: "CALL_ENDED" });
    setEndCallLoading(false);
    onLeaveCall();
    if (productUUID === "0f5ecf63-275a-45a8-81c6-cadde3edbc0f") {
      history.replace(`/order/${orderUUID}/suggestions`);
    } else if (productUUID === trtVideoConsultationProductUUID) {
      history.replace(`/order/${orderUUID}/prescribe-trt-medication`);
    } else {
      history.replace(`/order/${orderUUID}`);
    }
  }, [call, history, onLeaveCall, orderUUID, productUUID]);

  return (
    <>
      <Tooltip title={"End call"}>
        <LoadingButton
          loading={endCallLoading || callLoading || query.isLoading}
          disabled={callLoading}
          variant="contained"
          color="error"
          onClick={() => {
            setShowModal(true);
          }}
        >
          <span className="str-video__icon str-video__icon--call-end"></span>
        </LoadingButton>
      </Tooltip>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle id="alert-dialog-title">{"Are you sure you would like to end this call ?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowModal(false)} variant="outlined">
            Cancel
          </Button>
          <LoadingButton loading={endCallLoading} onClick={handleEndCallClicked} variant="outlined">
            Yes, end
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface EndCallButtonProps {
  call: Call;
  orderUUID: string;
  callLoading: boolean;
  onLeaveCall: () => void;
}

export default EndCallButton;
