import { FC } from "react";
import { useSelector } from "react-redux";
import ReduxStateModel from "../../models/ReduxStateModel";
import RecommendationsShowSelectedItem from "./RecommendationsShowSelectedItem";

const ShowRecommendationsSelectedLabTests: FC = () => {
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);

  return (
    <>
      {consultationRecommendationsReducer.selectedBuildYourLabTestItems !== "SKIP" &&
        (consultationRecommendationsReducer.selectedBuildYourLabTestItems?.length ?? 0) > 0 && (
          <RecommendationsShowSelectedItem
            img={`https://dardocstorageaccount.blob.core.windows.net/dardocpictures/imageelwkdjfkasdhfkjasb152.png`}
            title={
              consultationRecommendationsReducer.selectedBuildYourLabTestItems?.length === 1
                ? `1 biomarker selected`
                : `${consultationRecommendationsReducer.selectedBuildYourLabTestItems?.length} individual biomarkers selected`
            }
          />
        )}
      {consultationRecommendationsReducer.selectedLabTests !== "SKIP" &&
        consultationRecommendationsReducer.selectedLabTests?.map((labTest) => {
          return (
            <RecommendationsShowSelectedItem
              key={labTest.productUUID}
              img={labTest.mainImageUrl ?? ""}
              title={labTest.name ?? ""}
            />
          );
        })}
    </>
  );
};

export default ShowRecommendationsSelectedLabTests;
