import { FC } from "react";
import { useSelector } from "react-redux";
import ReduxStateModel from "../../models/ReduxStateModel";
import RecommendationsShowSelectedItem from "./RecommendationsShowSelectedItem";

const ShowSelectedRecommendationsIVDrips: FC<ShowSelectedRecommendationsIVDripsProps> = () => {
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);

  if (consultationRecommendationsReducer.selectedIVDrip === "SKIP") return null;

  const hasAddons = (consultationRecommendationsReducer.selectedCustomizations?.length ?? 0) > 0;

  return (
    <RecommendationsShowSelectedItem
      img={consultationRecommendationsReducer.selectedIVDrip?.mainImageUrl ?? ""}
      title={consultationRecommendationsReducer.selectedIVDrip?.name ?? ""}
      subtitle={
        hasAddons
          ? `Addons: ${consultationRecommendationsReducer.selectedCustomizations?.map((addon) => addon.engName ?? "").join(", ")}`
          : undefined
      }
    />
  );
};

interface ShowSelectedRecommendationsIVDripsProps {}

export default ShowSelectedRecommendationsIVDrips;
