import { FC } from "react";
import PharmacyBookingModel from "../../models/PharmacyBookingModel";
import MarkOrderDispatchedButton from "./MarkOrderDispatchedButton";
import MarkDeliveredButton from "./MarkDeliveredButton";
import StatusChip from "./StatusChip";
import AddAvailableItemsButton from "./AddAvailableItemsButton";

const DetailsViewDrawerHeader: FC<DetailsViewDrawerHeaderProps> = ({ order }) => {
  return (
    <div
      style={{
        padding: "16px 24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "64px",
        // marginTop: "50px",
      }}
    >
      {order.appStatus && <StatusChip status={order.appStatus} />}
      <div style={{ display: "flex", gap: "16px" }}>
        {order.appStatus === "PRESCRIPTION_UPLOADED" && (order.itemsPaidFor?.length ?? 0) === 0 && (
          <AddAvailableItemsButton order={order} />
        )}
        {order.appStatus === "PRESCRIPTION_UPLOADED" && (order.availableItems?.length ?? 0) > 0 && (
          <MarkOrderDispatchedButton order={order} />
        )}
        {order.appStatus === "DISPATCHED" && <MarkDeliveredButton order={order} />}
      </div>
    </div>
  );
};

interface DetailsViewDrawerHeaderProps {
  order: PharmacyBookingModel;
}

export default DetailsViewDrawerHeader;
