import { FC } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import RecommendationsButton from "./RecommendationsButton";
import ReduxStateModel from "../../models/ReduxStateModel";
import RecommendationsCheckCircle from "./RecommendationsCheckCircle";
import ShowSelectedRecommendationsIVDrips from "./ShowSelectedRecommendationsIVDrips";

const SuggestionsIVDripsButton: FC = () => {
  const consultationRecommendationsReducer = useSelector((state: ReduxStateModel) => state.consultationRecommendationsReducer);
  const { url } = useRouteMatch();
  const didSelectIVDrip =
    consultationRecommendationsReducer.selectedIVDrip !== undefined &&
    consultationRecommendationsReducer.selectedIVDrip !== "SKIP";

  return (
    <Link to={`${url}/iv-drips`}>
      <RecommendationsButton
        title="Suggest an IV Drip"
        bottom={didSelectIVDrip ? <ShowSelectedRecommendationsIVDrips /> : null}
        subtitle={
          didSelectIVDrip ? (
            <Box display={"flex"} gap={1} alignItems={"center"} marginTop={0.5}>
              <RecommendationsCheckCircle selected={true} size="16px" iconSize="12px" />
              <Typography fontSize={"14px"} lineHeight={"1"}>
                IV Drip selected
              </Typography>
            </Box>
          ) : (
            "Suggest the appropriate IV drips for efficient patient recovery."
          )
        }
        trailingText={didSelectIVDrip ? "Edit" : "Select"}
        startIcon={
          <img
            src="https://dardocstorageaccount.blob.core.windows.net/dardocpictures/Frameaskdljfkladsjfklashf101549.png"
            alt="Suggest an IV Drip"
            width={90}
            height={90}
            style={{ width: "30px", height: "30px" }}
          />
        }
      />
    </Link>
  );
};

export default SuggestionsIVDripsButton;
