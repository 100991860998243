import { Box, Typography } from "@mui/material";
import { FC } from "react";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const RecommendationsShowSelectedItem: FC<RecommendationsShowSelectedItemProps> = ({ img, subtitle, title }) => {
  return (
    <Box
      marginTop={2}
      sx={{
        backgroundColor: "white",
        paddingX: 1,
        paddingY: 1,
        borderRadius: "8px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display={"flex"} alignItems={"center"}>
        <img
          src={img}
          alt={title}
          style={{
            width: "40px",
            height: "40px",
            objectFit: "cover",
            borderRadius: "6px",
            marginRight: "8px",
          }}
        />
        <Box>
          <Typography letterSpacing={"-0.4px"} variant="subtitle2" fontWeight={500} lineHeight={"1"}>
            {title}
          </Typography>
          {subtitle && <Typography variant="caption">{subtitle}</Typography>}
        </Box>
      </Box>
      <ArrowForwardIosRoundedIcon sx={{ color: "#C5C5C7", fontSize: "16px" }} />
    </Box>
  );
};

interface RecommendationsShowSelectedItemProps {
  img: string;
  title: string;
  subtitle?: string;
}

export default RecommendationsShowSelectedItem;
