import Button from "@mui/material/Button";
import momentTimeZone from "../common/TimeManagement/momentTimezone";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Form, Select } from "antd";
import { timevalues } from "../timevalues";
import { useSelector } from "react-redux";
import { showMessage } from "../common/notification";
import { Row, Col } from "antd";
import "./style.css";

const { Option } = Select;

const minimumWorkingHours = 10;

function ManagePartnerTime({ saveChange }) {
  const [form] = Form.useForm();
  const startTime = Form.useWatch("startTime", form);
  const role = localStorage?.role ? JSON?.parse(localStorage?.role) : undefined;
  const manageDateTime = useSelector((state) => state.settingReducer.manageDateTime);

  const [time, settime] = useState({
    startTime: "",
    endTime: "",
  });

  const [disabledIndex, setdisabledIndex] = useState(0);

  // const onChange = (value, name) => {
  //   let tempcurrentday = { ...time };

  //   if (name === "startTime") {
  //     tempcurrentday.startTime = value === undefined ? "" : value;

  //     tempcurrentday.endTime = "";
  //     tempcurrentday.isStartEdited = true;
  //   }

  //   if (name === "endTime") {
  //     tempcurrentday.endTime = value === undefined ? "" : value;
  //     tempcurrentday.isEndEdited = true;
  //   }

  //   settime({ ...tempcurrentday });
  // };

  const handleSave = useCallback(
    (time) => {
      const now = moment();
      // console.log("--- time", time);
      const startMoment = moment(time.startTime, time.isStartEdited ? "HH:mm" : undefined).tz("Asia/Dubai");
      startMoment.set("year", now.get("year"));
      startMoment.set("month", now.get("month"));
      startMoment.set("day", now.get("day"));
      const startToday = moment(startMoment.format("hh:mmA"), "hh:mmA");
      const endMoment = moment(time.endTime, time.isEndEdited ? "HH:mm" : undefined).tz("Asia/Dubai");
      endMoment.set("year", now.get("year"));
      endMoment.set("month", now.get("month"));
      endMoment.set("day", now.get("day"));
      const endToday = moment(endMoment.format("hh:mmA"), "hh:mmA");
      const startEndDifference = endToday.diff(startToday);
      const differenceInHours = startEndDifference / 1000 / 60 / 60;
      if (differenceInHours < 10) {
        return showMessage("error", "The minimum difference between start and end working time must be 10 or more hours");
      }
      saveChange(
        {
          standardWorkingHr: time,
        },
        "standardWorkingHr"
      );
    },
    [saveChange]
  );

  useEffect(() => {
    const validateStartTime = () => {
      const start = time?.isStartEdited ? time?.startTime : momentTimeZone(time?.startTime);
      const getStartIndex = timevalues.findIndex((t) => t === start);
      setdisabledIndex(getStartIndex);
    };
    validateStartTime();
  }, [time]);

  useEffect(() => {
    if (manageDateTime?.partnerSettings?.standardWorkingHr?.startTime) {
      settime(manageDateTime?.partnerSettings?.standardWorkingHr);
    }
  }, [manageDateTime]);

  const handleFormSubmit = useCallback(
    (values) => {
      values.isStartEdited = true;
      values.isEndEdited = true;
      handleSave(values);
    },
    [handleSave]
  );

  return (
    <Row className="setting__container" style={{ height: "100%", width: "100%", rowGap: "1rem" }}>
      <p className="container__title">Manage Admin Working Time</p>
      <Form
        form={form}
        style={{ width: "100%" }}
        onFinish={handleFormSubmit}
        onFinishFailed={(error) => {
          console.log("--- onFinishFailed", error);
        }}
        initialValues={{
          startTime: moment(manageDateTime?.partnerSettings?.standardWorkingHr?.startTime).format("HH:mm"),
          endTime: moment(manageDateTime?.partnerSettings?.standardWorkingHr?.endTime).format("HH:mm"),
          // startTime:
          //   time?.startTime !== undefined && time?.startTime !== ""
          //     ? time?.isStartEdited
          //       ? time?.startTime
          //       : momentTimeZone(time?.startTime)
          //     : null,
          // endTime:
          //   time?.endTime !== undefined && time?.endTime !== ""
          //     ? time?.isEndEdited
          //       ? time?.endTime
          //       : momentTimeZone(time?.endTime) === "00:00"
          //       ? "24:00"
          //       : momentTimeZone(time?.endTime)
          //     : null,
        }}
      >
        <Row
          className="setting__time"
          style={{
            width: "100%",
            justifyContent: "space-between",
            rowGap: "1rem",
          }}
        >
          <Col xs={24} sm={17}>
            <Row
              className="individual__time"
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "8px",
                padding: "0.2rem",
                width: "100%",
              }}
            >
              <Col xs={24} sm={12}>
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col span={4}>
                    <p style={{ margin: 0 }}>From</p>
                  </Col>
                  <Col span={19}>
                    <Form.Item style={{ margin: "0px" }} name="startTime">
                      <Select
                        disabled={role === "STAFF_MANAGER" ? true : false}
                        allowClear={true}
                        bordered={false}
                        showSearch
                        style={{
                          width: "100%",
                          backgroundColor: "transparent",
                        }}
                        placeholder="Start time"
                        optionFilterProp="children"
                        onChange={(value) => {
                          console.log("---- start time change", value);
                        }}
                        // value={

                        // }
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {timevalues?.map((time, key) => (
                          <Option value={time} key={key}>
                            {time}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col span={4}>
                    <p style={{ margin: 0 }}>To</p>
                  </Col>
                  <Col span={19}>
                    <Form.Item
                      style={{ margin: "0px" }}
                      name="endTime"
                      rules={[
                        () => ({
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.reject("Please select the working end time");
                            }
                            const startMoment = moment(startTime, "HH:mm");
                            const endMoment = moment(value, "HH:mm");
                            if (endMoment.isSameOrBefore(startMoment)) {
                              return Promise.reject("End time must be after the start time");
                            }
                            const durationDifference = moment.duration(endMoment.diff(startMoment));
                            if (durationDifference.asHours() < minimumWorkingHours) {
                              return Promise.reject(`Minimum working time is ${minimumWorkingHours} hours`);
                            }
                            console.log("end time valuedator", value);
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Select
                        disabled={
                          role === "STAFF_MANAGER" ? true : time?.startTime === undefined || time?.startTime === "" ? true : false
                        }
                        allowClear={true}
                        bordered={false}
                        showSearch
                        style={{
                          width: "100%",
                          backgroundColor: "transparent",
                        }}
                        placeholder="End time"
                        optionFilterProp="children"
                        // onChange={(value) => onChange(value, "endTime")}
                        // value={

                        // }
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {timevalues?.map((time, key) => (
                          <Option value={time} key={key} disabled={key <= disabledIndex ? true : false}>
                            {time}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                borderRadius: "1.4rem",
                padding: "0.3rem 2rem",
                color: "#fff",
                float: "right",
              }}
              // onClick={handleSave}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}

export default ManagePartnerTime;
