import { Box } from "@mui/material";
import { FC } from "react";

const PatientAssesmentFormItem: FC<PatientAssesmentFormItemProps> = ({ label, value }) => {
  return (
    <div style={{ padding: "0px 1rem" }}>
      <Box
        sx={{
          padding: "1rem 0px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid gray",
          "@media (max-width: 700px)": {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        }}
      >
        <Box
          sx={{
            flex: "2 2 0",
            // width: "350px",
            // "@media (max-width: 640px)": {
            //   width: "200px",
            // },
          }}
        >
          {label}
        </Box>
        <div style={{ flex: "3 3 0", fontWeight: "bold", textAlign: "end" }}>{value}</div>
      </Box>
    </div>
  );
};

interface PatientAssesmentFormItemProps {
  label: string;
  value: React.ReactNode;
}

export default PatientAssesmentFormItem;
