import { Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC } from "react";

const RecommendationsButton: FC<RecommendationsButtonProps> = ({ subtitle, trailingText, title, bottom, startIcon, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        marginBottom: 2,
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        backgroundColor: "#F8F8F8",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: bottom ? "flex-start" : "center",
        paddingX: 3,
        paddingY: 2,
        borderRadius: "12px",
        color: "black",
        "& .MuiButton-startIcon": {
          marginRight: "16px",
        },
        "& .MuiButton-endIcon .MuiTypography-root": {
          fontSize: "13px",
        },
        "&:hover": {
          backgroundColor: grey[100],
        },
      }}
    >
      <Box marginRight={2} marginTop={bottom ? 2 : 0}>
        {startIcon}
      </Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} flex={"1 1 0"}>
        <Box width={"100%"} display={"flex"}>
          <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}>
            <Typography fontSize={"24px"} fontWeight={600} lineHeight={"1.5"} letterSpacing={"-0.374px"}>
              {title}
            </Typography>
            <Typography fontSize={"14px"} letterSpacing={"-0.12px"} color={"#1D1D1F"}>
              {subtitle}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            sx={{
              "& .MuiButtonBase-root.MuiButton-root": {
                minWidth: "unset",
              },
            }}
          >
            <Button sx={{ color: "#258E8F", width: "100px", textTransform: "none" }}>{trailingText}</Button>
          </Box>
        </Box>
        {bottom}
      </Box>
    </Box>
  );
};

interface RecommendationsButtonProps {
  title: string;
  trailingText: string;
  bottom?: React.ReactNode;
  subtitle: React.ReactNode;
  startIcon: React.ReactNode;
  onClick?: () => void;
}

export default RecommendationsButton;
