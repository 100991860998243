import { useQuery } from "@tanstack/react-query";
import { searchBuildYourLabTestBiomarkers } from "../services/schemas.services";
import { useMemo } from "react";

export default function useBuildYourLabTestBiomarkers(params: { searchString?: string }) {
  const queryKey = useMemo(() => {
    return ["/api/v1/schemas/search-for-build-your-lab-test-addon", params.searchString];
  }, [params.searchString]);

  const query = useQuery(queryKey, () => searchBuildYourLabTestBiomarkers({ searchString: params.searchString ?? "" }), {
    enabled: !!params.searchString,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return { queryKey, query };
}
